import { combineReducers } from 'redux';
import settings from './settingsReducer';
import application from './Application';
// import list from './List';
import list from './listReducer';
import auth from './authReducer';
import account from './accountReducer';
import subscriber from './subscriberReducer';

export default combineReducers({
  settings,
  application,
  auth,
  account,
  subscriber,
  list,
});
