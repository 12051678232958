import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { createSubscriberSelectLabel } from 'common/Util';
import { getSubscriberSettingsSelector } from './settingsSelectors';


const getDetails = (state, props) => state.account;

export const accountDetailsSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => details,
);

export const getAccountDebtSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => details.get('debt', ''),
)

export const getAccountOutstandingSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => details.get('outstanding', ''),
)

export const getAccountFullNameSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => {
    const fullName = [details.get('firstname', ''), details.get('lastname', '')]
    .filter(str => str.length > 0)
    .join(' ');
    return fullName;
  },
);

export const getAccountSubscribersSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => {
    const subscribers = details.get('subscribers', Immutable.List());
    return subscribers;
  }
);

export const isAccountInvoicesLoadedSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => details.has('invoices'),
);

export const getAccountInvoicesSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => {
    return details.get('invoices', null);
  }
);

export const isAccountChargesLoadedSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => details.has('charges'),
);

export const getAccountChargesSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => {
    return details.get('charges', null);
  }
);

export const isAccountStatementLoadedSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => details.has('statements'),
);

export const getAccountStatementSelector = createSelector(
  getDetails,
  (details = Immutable.Map()) => {
    return details.get('statements', null);
  }
);

export const getAccountSubscribersOptionsSelector = createSelector(
  getAccountSubscribersSelector,
  getSubscriberSettingsSelector,
  (subscribers = Immutable.List(), subscriberSettings = Immutable.Map()) => subscribers
    .map(subscriber => ({
      value: subscriber.get('sid', ''),
      label: createSubscriberSelectLabel(subscriber, subscriberSettings),
    }))
    .toArray()
);