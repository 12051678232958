import React, { useEffect, useCallback } from 'react';
import Immutable from 'immutable';
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Container, Card } from 'react-bootstrap';
import List from 'components/List';
import { showWarning } from 'actions/alertsActions';
import { 
  getAccountChargesSelector,
  isAccountChargesLoadedSelector
} from 'selectors/accountSelectors';
import {
  getAccountCharges,
} from 'actions/accountActions';
import { getBillingCyclesOptions } from 'common/Util';
import { invoicingDaySelector } from 'selectors/settingsSelectors';


const Charges = () => {

  const dispatch = useDispatch();
  const intl = useIntl();
  const data = useSelector(getAccountChargesSelector);
  const isDataLoaded = useSelector(isAccountChargesLoadedSelector);
  const invoicingDay = useSelector(invoicingDaySelector);

  const getListData = useCallback(() => {
    dispatch(getAccountCharges())
    .catch((e) => {
      dispatch(showWarning(intl.formatMessage({
        id:`error.${e.message}`,
      }, {fields: e.fields}
      )));
    });
  },[dispatch, intl]);


  useEffect(() => {
    if (!isDataLoaded) {
      getListData();
    }
  }, [getListData, isDataLoaded]);

  if (!isDataLoaded) {
    return null;
  }

  const tableFields = Immutable.fromJS([
    { id: 'urt', title: 'table.charge.date', type: 'date'},
    { id: 'due_date', title: 'table.charge.due_date', type: 'date'},
    { id: 'description', title: 'table.charge.description'},
    { id: 'quantity', title: 'table.charge.quantity'},
    { id: 'amount', title: 'table.charge.total', type: 'price'},
  ]);

  const tableFilters = Immutable.fromJS([
    {id: 'billrun', multi: false, options: getBillingCyclesOptions(invoicingDay) },
  ]);

  const tableActions = Immutable.fromJS([
    {type: 'refresh', onClick: getListData, enable: isDataLoaded }
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <Card className="p-3">
            <List
              entity='charge'
              data={data}
              fields={tableFields}
              filters={tableFilters}
              listActions={tableActions}
              reloadData={getListData}
            />
          </Card>
        </Col>
      </Row>
  </Container>
  );
}

export default Charges;
