import Immutable from 'immutable';
import { actions } from 'actions/alertsActions';


const defaultState = Immutable.List();

const alertsReducer = (state = defaultState, action) => {
  switch (action.type) {

    case actions.SHOW_ALERT:
      return state.insert(0, action.alert);

    case actions.DISMISS_ALERT:
      return state.filter(alert => alert.get('id') !== action.id);

    case actions.DISMISS_ALL_ALERTS:
      return defaultState;

    default:
      return state;
  }
}

export default alertsReducer;
