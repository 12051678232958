import React, { useEffect } from 'react';
import Immutable from 'immutable';
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Container, Card } from 'react-bootstrap';
import List from 'components/List';
import { 
  getAccountInvoicesSelector,
  isAccountInvoicesLoadedSelector
} from 'selectors/accountSelectors';
import { showDanger } from 'actions/alertsActions';
import {
  getAccountInvoices,
  downloadInvoice,
} from 'actions/accountActions';


const Invoices = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const invoices = useSelector(getAccountInvoicesSelector);
  const isAccountInvoicesLoaded = useSelector(isAccountInvoicesLoadedSelector);

  useEffect(() => {
    if (!isAccountInvoicesLoaded) {
      dispatch(getAccountInvoices());
    }
  }, [isAccountInvoicesLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isAccountInvoicesLoaded) {
    return null;
  }

  const onClickDownload = (data, type) => {
    const invoiceId = data.get('invoice_id', '');
    const billrunKey = data.get('billrun_key', '');
    dispatch(downloadInvoice(invoiceId, billrunKey))
      .then(res => {
        if (res.data) {
          dispatch(showDanger(res.data));
        }
      });
  }

  const tableFields = Immutable.fromJS([
    { id: 'aid', title: 'table.invoice.customer'},
    { id: 'invoice_id', title: 'table.invoice.invoice_number'},
    { id: 'invoice_date', title: 'table.invoice.date', type:'date' },
    { id: 'amount', title: 'table.invoice.charge', type: 'price'},
    { id: 'paid', title: 'table.invoice.paid', type: 'ofOn'},
  ]);

  const tableActions = Immutable.fromJS([]);

  const rowActions = Immutable.fromJS([
    { type: 'download', showIcon: true, helpText: `${intl.formatMessage({id:'table.download'})} ${intl.formatMessage({id:'table.invoice.invoice'})}` ,onClick: onClickDownload, show: true },
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <Card className="p-3">
            <List 
              data={invoices}
              fields={tableFields}
              listActions={tableActions}
              rowActions={rowActions}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}


export default Invoices;
