const systemConfig = {
  billingCyclesToDisplayInSelect: 12,
  listSizes:[5, 10, 20, 50],
  defaultListSize: 50,
  passwordMinLength: 7,
  passwordMustUppercase: true,
  passwordMustLowercase: true,
  passwordMustNumber: true,
  passwordMustSymbols: true,
  captchaLength: 5,
  captchaChars: 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789',
  unlimitedInt: 9223370000000000000,
};

export default systemConfig;
