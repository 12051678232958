import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';


const Action = (props) => {
  const {
    type,
    label,
    data,
    actionStyle,
    showIcon,
    actionSize,
    actionClass,
    show,
    enable,
    renderFunc,
    onClick,
    helpText,
  } = props;

  const showAction = useMemo(() => (
    (typeof show === 'boolean' && show)
    || (typeof show === 'function' && show(data, type))
  ), [show, data, type]);

  const isEnable = useMemo(() => (
    typeof enable === 'function' ? enable(data, type) : enable
  ), [enable, data, type]);

  const isCustomRender = useMemo(() => (
    renderFunc !== null && typeof renderFunc === 'function'
  ), [renderFunc]);

  const onClickAction = useCallback(() => {
    onClick(data, type);
  }, [onClick, data, type]);

  const iconClass = useMemo(() => classNames('bi', {
    'bi-trash': type === 'remove',
    'bi-pencil': type === 'edit',
    'bi-eye': type === 'view',
    'bi-download': type === 'download',
    'bi-arrow-clockwise': type === 'refresh',
    'bi-file-earmark-spreadsheet': type === 'csv',
  }), [type]);

  const btnClass = useMemo(() => classNames('ps-1', 'pe-1', actionClass), [actionClass]);

  if (!showAction) {
    return null;
  }

  return (
    <span className="action-button">
      { isCustomRender ? props.renderFunc(props)
        : (
          <Button
            onClick={onClickAction}
            variant={actionStyle === 'default' ? undefined : actionStyle}
            size={actionSize}
            className={btnClass}
            disabled={!isEnable}
            title={helpText}
          >
            { showIcon && <i className={iconClass} /> }
            { showIcon && label.length > 0 && <span>&nbsp;</span> }
            { label.length > 0 && label}
          </Button>
        )
      }
    </span>
  );
};

Action.defaultProps = {
  type: '',
  data: null,
  index: '',
  label: '',
  helpText: '',
  actionStyle: 'link',
  actionSize: undefined,
  actionClass: '',
  showIcon: true,
  enable: true,
  show: true,
  renderFunc: null,
  onClick: () => {},
};

Action.propTypes = {
  type: PropTypes.string,
  data: PropTypes.any,
  index: PropTypes.any,
  label: PropTypes.string,
  showIcon: PropTypes.bool,
  actionStyle: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'light', 'link']),
  actionSize: PropTypes.oneOf(['lg', 'sm']),
  actionClass: PropTypes.string,
  helpText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  enable: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  renderFunc: PropTypes.func,
  onClick: PropTypes.func,
};

export default memo(Action);
