import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux'
import Immutable from 'immutable';
import moment from 'moment';
import { FormattedMessage, FormattedDate } from 'react-intl'
import { Card, Col, Row, Badge } from 'react-bootstrap';
import DisplayValue from 'components/DisplayValue';
import { Loading } from 'components/Elements';
import {
  getSubscriberPlanSelector,
  getSubscriberBriefLimitedUsageSelector,
  getSubscriberBriefUnlimitedUsageSelector,
  isSubscriberDetailsExistsSelector,
  getSubscriberOverBundleUsageSelector,
} from 'selectors/subscriberSelector';
import { invoicingDaySelector } from 'selectors/settingsSelectors';
import { getInvoicingDate } from 'common/Util';

const UsageChart = lazy(() => import('components/Elements/UsageChart'));


const HomeSubscriberDetails = () => {
  const subscriberPlan = useSelector(getSubscriberPlanSelector);
  const subscriberUsages = useSelector(getSubscriberBriefLimitedUsageSelector);
  const subscriberUnlimitedUsages = useSelector(getSubscriberBriefUnlimitedUsageSelector);
  const subscriberOverBundleUsage = useSelector(getSubscriberOverBundleUsageSelector);
  const isSubscriberDetailsExists = useSelector(isSubscriberDetailsExistsSelector);
  const invoicingDay = useSelector(invoicingDaySelector);
  const now = moment().startOf('day');
  const nextInvoicingDay = getInvoicingDate(invoicingDay);
  const daysLeft = nextInvoicingDay.diff(now, 'days');

  if (!isSubscriberDetailsExists) {
    return(
      <Card.Text className="ms-3">
        <FormattedMessage id="lbl.select_subscriber" />
      </Card.Text>
    );
  }

  return (
    <>
      <p>
        <Card.Text className="h5 align-bottom" as="span">
          <FormattedMessage id="lbl.my_plan" />:
        </Card.Text> <Badge pill bg="salt">{subscriberPlan}</Badge>
      </p>
      <hr className="mt-0 mb-2"/>
      <p>
        <Card.Text className="h5 align-bottom" as="span">
          <FormattedMessage id="lbl.days_cycle_left" />:
        </Card.Text> <Badge pill bg="salt">{daysLeft}</Badge>
      </p>
      <hr className="mt-0 mb-2"/>
      <p>
        <Card.Text className="h5 align-bottom" as="span">
          <FormattedMessage id="lbl.next_invoicing_date" />:
        </Card.Text> <Badge pill bg="salt"><FormattedDate value={nextInvoicingDay} /></Badge>
      </p>
      {!subscriberUsages.isEmpty() && (
        <>
          <hr className="mt-0 mb-2"/>
          <Card.Title><FormattedMessage id="lbl.plan_usage" />:</Card.Title>
          <Row className="">
            {subscriberUsages.map((subscriberUsage, groupName) => {
              const used = subscriberUsage.get('used', 0)
              const total = subscriberUsage.get('total', 0);
              const type = subscriberUsage.get('usage_type', '').toLocaleLowerCase();
              return (
                <Col className="p-3 usage-chart" key={groupName}>
                  <Suspense fallback={<Loading />}>
                    <UsageChart
                      name={groupName}
                      used={used}
                      total={total}
                      type={type}
                    />
                  </Suspense>
                </Col>
              );
            }).toList()}
          </Row>
        </>
      )}

      {!subscriberUnlimitedUsages.isEmpty() && (
        <>
          <hr className="mt-0 mb-2"/>
          <Card.Title><FormattedMessage id="lbl.plan_unlimited_usage" />:</Card.Title>
          <Row className="justify-content-center">
            <ul>
            {subscriberUnlimitedUsages.map((usage, name) => {
              const used = usage.get('used', 0);
              const type = usage.get('usage_type', '').toLocaleLowerCase();
              const config = Immutable.fromJS({additionData: {usaget: type}});
              return (
                <li className="ms-5" key={name}>
                  <FormattedMessage id={`usage_groups.${name}`} defaultMessage={name} />
                  {": "}
                  <Badge pill bg="salt" className="ms-1">
                    <DisplayValue type='usage' value={used} config={config} className="d-inline"/>
                  </Badge>
                </li>
              );
            }).toList()}
            </ul>
          </Row>
      </>)}

      {!subscriberOverBundleUsage.isEmpty() && (
        <>
          <hr className="mt-0 mb-2"/>
          <Card.Title><FormattedMessage id="lbl.out_group_usage" />:</Card.Title>
          <Row className="justify-content-center">
            <ul>
            {subscriberOverBundleUsage.map((usage, type) => {
              const outGroupUsed = usage.getIn(['out_group', 'usagev'], 0);
              const overGroupUsed = usage.getIn(['over_group', 'usagev'], 0);
              const used = outGroupUsed + overGroupUsed;
              if (used === 0) {
                return null;
              }
              const config = Immutable.fromJS({additionData: {usaget: type}});
              return (
                <li className="ms-5" key={type}>
                  <FormattedMessage id={`usage_type.${type}`} defaultMessage={type} />
                  {": "}
                  <Badge pill bg="salt" className="ms-1">
                    <DisplayValue type='usage' value={used} config={config} className="d-inline"/>
                  </Badge>
                </li>
              );
            }).toList()}
            </ul>
          </Row>
      </>)}
    </>
  )
}

export default HomeSubscriberDetails;
