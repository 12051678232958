
import React from 'react';
import Immutable from 'immutable';
import { Badge } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import DisplayValue from 'components/DisplayValue';
import { getUsageColor } from 'common/Util';


const UsageChart = ({ name, used, total, type }) => {

  const percentageVal = total > 0 ? (used / total * 100).toFixed() : 0;
  const color = getUsageColor(parseFloat(percentageVal));
  const chartStyle = { path: { stroke: color}, text: { fill: color } }
  const config = Immutable.fromJS({additionData: {usaget: type}});
  return (
    <div className="text-center">
      <Badge pill bg="salt" className="text-center">
        <FormattedMessage id={`usage_groups.${name}`} defaultMessage={name} />
      </Badge>
      <div className="mt-2 mb-1 ms-3 me-3 circular-progress-bar-chart">
        <CircularProgressbarWithChildren value={percentageVal} styles={chartStyle}>
          <>
            <h5><FormattedMessage id={`usage_type.${type}`} /></h5>
            <h4 style={{color: color}}>{percentageVal}%</h4>
          </>
        </CircularProgressbarWithChildren>
      </div>
      <Badge pill bg="salt" className="text-center">
        <FormattedMessage id="lbl.used_of"  values={{
          used: <DisplayValue type='usage' value={used} config={config.setIn(['additionData', 'show_units'], false)} />,
          of: <DisplayValue type='usage' value={total} config={config} />}}/>
      </Badge>
    </div>
  );
}

export default UsageChart;




