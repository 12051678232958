import Immutable from 'immutable';
import { actions } from 'actions/modalActions';


const defaultState = Immutable.Map({
  form: Immutable.Map(),
  confirm: Immutable.Map(),
});

const modalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.EDIT_FORM_SHOW: {
      const { item, component, config } = action;
      return state.setIn(['form'], Immutable.Map({
        item,
        component,
        config: Immutable.fromJS(config),
        show: true,
      }));
    }

    case actions.EDIT_FORM_HIDE: {
      return state.setIn(['form'], Immutable.Map());
    }

    case actions.EDIT_FORM_SET_ITEM: {
      return state.setIn(['form', 'item'], action.item);
    }

    case actions.EDIT_FORM_SET_ERROR: {
      const { fieldId = null, message = null } = action;
      if (fieldId === null) {
        return state.deleteIn(['form', 'errors']);
      }
      if (message === null) {
        return state.deleteIn(['form', 'errors', fieldId]);
      }
      return state.setIn(['form', 'errors', fieldId], message);
    }

    case actions.EDIT_FORM_UPDATE_ITEM_FIELD: {
      const { path, value } = action;
      const arrayPath = Array.isArray(path) ? path : [path];
      return state.setIn(['form', 'item', ...arrayPath], value);
    }

    case actions.EDIT_FORM_DELETE_ITEM_FIELD: {
      const { path } = action;
      const arrayPath = Array.isArray(path) ? path : [path];
      return state.deleteIn(['form', 'item', ...arrayPath]);
    }

    case actions.CONFIRM_SHOW: {
      const { config } = action;
      return state.set('confirm', Immutable.Map({ ...config, show: true }));
    }

    case actions.CONFIRM_HIDE: {
      return state.set('confirm', Immutable.Map());
    }


    default:
      return state;
  }
}


export default modalReducer;