import React from 'react';
import { FormattedMessage} from 'react-intl';


const Page404 = () => (    
  <article className="mt-5">
    <h2>
      <FormattedMessage id="page.title.404" />
    </h2>
    <p><FormattedMessage id="page.details.404" /></p>
  </article>
);

export default Page404;
