const he = {
  version: "גירסה",
  page: {
    title: {
      about: "עלינו",
      home: "בית",
    }
  },
  lbl : {
    loading:"טוען...",
  },
};

export default he;