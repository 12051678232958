import download from 'downloadjs';
import { 
  startProgressBar,
  finishProgressBar,
  dismissProgressIndicator,
  finishProgressLoading,
  startProgressLoading,
} from 'actions/progressIndicatorActions';
import { showDanger, showSuccess, showWarning } from 'actions/alertsActions';
import { userDoLogout } from 'actions/authActions';
import {
  getUserAuthorizedTokenSelector,
  getAppAuthorizedTokenSelector,
} from 'selectors/authSelector';
import { getConfig, getFileNameFormResponseHeader } from './Util';

export const API_STATUS_SUCCESS = 1;
export const API_STATUS_ERROR = 0;
export const API_STATUS_WARNING = 2;


// Helper function to simulate API response with delay
export const delay = (sec = 2, success = true, mock = { success: true }) => (dispatch) => {
  dispatch(startProgressBar());
  return new Promise((resolve, reject) => {
    const callback = () => {
      dispatch(finishProgressBar());
      if (success) {
        return resolve(mock);
      }
      return reject(mock);
    }
    setTimeout(callback, sec * 1000);
  });
}

// Helper function to remove status property from response object
const removeStatus = ({ status, ...rest }) => rest; // eslint-disable-line no-unused-vars

// Helper function to check response user login
const checkLogin = (dispatch, response = {}) => {
  if (response.status === 401) {
    dispatch(userDoLogout())
    throw new Error('login is required');
  }
  return response;
};

// Helper function to check response status OK
const checkStatus = (response = null) => {
  if (!response || response.status === API_STATUS_ERROR) { // Check status for error
    throw response;
  }
  if (response && response.error && response.error.length > 0) {
    throw response;
  }
  return response;
};

// Helper function to build query options
const buildQueryOptions = (options = null) => {
  // default options
  const requestOptions = {
    credentials: 'include',
    method : "GET",
    "Content-Type": "application/json",
  };
  // override / add option
  if (options) {
    Object.assign(requestOptions, options);
  }
  return requestOptions;
};

// Helper function to build API url
const buildApiString = (params = {}) => {
  switch (params.api) {
    case 'oauth2':
      return `/oauth2/token`;
    default:
      return `/portal/${params.api}`;
  }
};

// Helper function to build query params string
const buildQueryString = (params = null) => {
  let queryParams = '';
  if (params && Array.isArray(params) && params.length > 0) {
    queryParams = params.reduce((previousValue, currentValue, currentIndex) => {
      const key = Object.keys(currentValue)[0];
      const prev = (currentIndex === 0) ? previousValue : `${previousValue}&`;
      return `${prev}${encodeURIComponent(key)}=${encodeURIComponent(currentValue[key])}`;
    }, '?');
  }
  // Set server debug flag if it enabled in config file
  if (process.env.NODE_ENV === "development" && window.serverApiDebug === true) {
    queryParams += (queryParams.length > 0) ? '&' : '?';
    queryParams += getConfig(['env','API_DEBUG_PARAM'], '');
  }
  return queryParams;
};

// Handel API success (ugly code to handle non standard
// API responses - should be improved with BillAPI)
export const apiBillRunSuccessHandler = (success, message = false) => (dispatch) => {
  const { details, data, status, warnings = 'warning' } = success.data.data;
  dispatch(finishProgressBar());
  let responseData = null;
  if (typeof details !== 'undefined') {
    responseData = details;
  } else if (typeof data !== 'undefined') {
    responseData = data;
  }
  if (status === API_STATUS_SUCCESS) {
    if (message) {
      dispatch(showSuccess(message));
    }
  } else if (status === API_STATUS_WARNING) { // Check for warning
    try {
      const warningMessages = Array.isArray(warnings) ? warnings : [warnings];
      warningMessages.forEach((warningMessage) => {
        dispatch(showWarning(warningMessage, 15000));
      });
    } catch (e3) { /* No success object or status */ }
  }

  return ({ status, data: responseData });
};

// Handel API errors (ugly code to handle non standard API responses
// - should be improved with standard BillAPI)
export const apiBillRunErrorHandler = (error, defaultMessage = 'Error, please try again...') => (dispatch) => {
  if (process.env.NODE_ENV === "development") {
    console.log('Api Error Handler, error: ', error); // eslint-disable-line  no-console
  }
  dispatch(dismissProgressIndicator());
  if (defaultMessage !== false) {
    let errorMessage;
    if (typeof error.message === 'string') {
      errorMessage = error.message;
    } else if (typeof error?.error === 'string') {
      errorMessage = error.error;
    } else if (typeof error?.error?.error_description === 'string') {
      errorMessage = error.error.error_description;
    } else if (typeof error?.error?.details === 'string') {
      errorMessage = error.error.details;
    } else if (typeof error?.error?.error?.data?.message === 'string') {
      errorMessage = error.error.error.data.message;
    } else if (typeof error?.error?.message === 'string') {
      errorMessage = error.error.message
    } else if (typeof error?.error?.error?.message === 'string') {
      errorMessage = error.error.error.message
    } else if (typeof error?.error?.error?.error?.message === 'string') {
      errorMessage = error.error.error.error.message;
    } else if (typeof error?.error?.error?.desc === 'string') {
      errorMessage = error.error.error.desc
    } else if (typeof error?.error?.error === 'string') {
      errorMessage = error.error.error;
    } else {
      errorMessage = defaultMessage;
    }
    dispatch(showDanger(errorMessage || defaultMessage));
  }
  return ({
    status: API_STATUS_ERROR,
    error,
  });
};

const promiseTimeout = (ms, promise, timeOutMessage = 'Request timeout') => new Promise(
  (resolve, reject) => {
    // create a timeout to reject promise if not resolved
    const promiseTimeoutTimer = setTimeout(() => {
      reject(new Error(timeOutMessage));
    }, ms);

    promise
      .then((response) => {
        clearTimeout(promiseTimeoutTimer);
        resolve(response);
      })
      .catch((error) => {
        clearTimeout(promiseTimeoutTimer);
        reject(error);
      });
  },
);

export const openWindowWithPost = query => {
  const url = `${getConfig(['env','API_URL'], '')}${buildApiString(query)}`;
  const data = query.params;
  const form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";
  data.forEach((currentValue) => {
    const key = Object.keys(currentValue)[0];
    let input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = currentValue[key];
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export const buildRequestUrl = (query) => {
  return `/api${buildApiString(query)}${buildQueryString(query.params)}`;
}

// Send Http request
const sendHttpRequest = (dispatch, query, apiParams = {}) => {
  // Create Api URL
  const url = buildRequestUrl(query, apiParams);
  const requestOptions = buildQueryOptions(query.options);
  const timeout = query.timeout || getConfig(['env', 'API_TIMEOUT'], 300000);
  let fileType = 'text/plain';
  let fileName = apiParams.default_filename || 'file';
  return promiseTimeout(timeout, fetch(url, requestOptions), apiParams.timeOutMessage)
    .then(res => checkLogin(dispatch, res))
    .then(res => {
      fileType = res.headers.get('content-type');
      if (fileType === "application/pdf") {
        fileName = getFileNameFormResponseHeader(res.headers.get('content-disposition'), fileName);
        return res.blob();
      }
      if (fileType === 'text/html; charset=UTF-8') {
        return res.text();
      }
      return res.json();
    })
    .then(checkStatus)
    .then(data => {
      if (data.type === "application/pdf") {
        return download(data, fileName, fileType);
      }
      return ({ data, status: 1 })
    })
    .catch(error => ({ error, status: 0 }));
};

// Send to API
export const apiBillRun = (dispatch, request, params = {})  => {
  dispatch(startProgressLoading());
  return sendHttpRequest(dispatch, request, params)
    .then((response) => {
      dispatch(finishProgressLoading());
      const isSuccess = response.status !== 0;
      const data = removeStatus(response);
      if (isSuccess) {
        return Promise.resolve({ data: data.data });
      }
      return Promise.reject({ error: data.error });
    });
};

export const apiBillRunUser = (request, params = {})  => (dispatch, getState) => {
  const store = getState();
  const authToken = getUserAuthorizedTokenSelector(store); 
  const userAuthToken = `${authToken.type} ${authToken.token}`;
  if (!request.options) {
    request.options = {};
  }
  if (!request.options.headers) {
    request.options.headers = {};
  }
  request.options.headers.Authorization = userAuthToken;
  return apiBillRun(dispatch, request, params)
};

export const apiBillRunApp = (request, params = {})  => (dispatch, getState) => {
  const store = getState();
  const authToken = getAppAuthorizedTokenSelector(store); 
  const appAuthToken = `${authToken.type} ${authToken.token}`;
  if (!request.options) {
    request.options = {};
  }
  if (!request.options.headers) {
    request.options.headers = {};
  }
  request.options.headers.Authorization = appAuthToken;
  return apiBillRun(dispatch, request, params)
};
