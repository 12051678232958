import { LoginForm } from 'components/Elements';


const Login = () => (
  <article className="mt-5">
    <LoginForm />
  </article>
);

export default Login;
