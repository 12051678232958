import React from 'react';
import Immutable from 'immutable';
import classNames from 'classnames';
import { Badge } from 'react-bootstrap';

const OfOn = ({ value, config = Immutable.Map() }) => {
  const isOn = value === '1' || value === 1 || value === true || value === 'TRUE' || value === 'true';
  const style = classNames({
    'success': isOn,
    'danger': !isOn,
  });
  return (<Badge bg={style}> </Badge>);
}

export default OfOn;

