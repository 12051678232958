import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { getEntityConfigFieldByName } from 'common/Util';

const getSettings = (state, props) => state.settings;

export const getLanguageSettingsSelector =  createSelector(
  getSettings,
  (settings = Immutable.Map()) => settings.get('language', Immutable.Map()),
);

export const getSubscriberSettingsSelector =  createSelector(
  getSettings,
  (settings = Immutable.Map()) => {
    let subscriberSettings = settings.getIn(['subscribers', 'subscriber'], Immutable.Map());
    const fieldsDisplayConfig = subscriberSettings.get('display_config', Immutable.Map());
    subscriberSettings = subscriberSettings.update('fields', Immutable.List(), fields => {
      return fields.map(field => {
        const fieldName = field.get('field_name', '');
        const fieldDisplayConfig = fieldsDisplayConfig.get(fieldName, Immutable.Map());
        if (!fieldDisplayConfig.isEmpty()) {
          return field.set('display_config', fieldDisplayConfig);
        }
        return field;
      })
    });
    const fieldsConfig = subscriberSettings.get('fields', Immutable.List());
    subscriberSettings = subscriberSettings.update('display_in_card', Immutable.Map(), fields => {
      return fields.map(field => {
        const fieldName = field.get('field_name', '');
        let config = getEntityConfigFieldByName(fieldsConfig, fieldName);
        if (fieldsDisplayConfig.has(fieldName) && !config.has('display_config')) {
          config = config.set('display_config', fieldsDisplayConfig.get(fieldName));
        }
        return field.merge(config);
      })
    })
    return subscriberSettings;
  },
);

export const msisdnFieldSettingsSelector =  createSelector(
  getSubscriberSettingsSelector,
  (subscriberSetting = Immutable.Map()) => {
    const msisdnField = subscriberSetting.get('msisdn_field', '');
    return subscriberSetting.get('fields', Immutable.List())
      .find(field => field.get('field_name', '') === msisdnField, null, Immutable.Map());
  }
);

export const getAccountSettingsSelector =  createSelector(
  getSettings,
  (settings = Immutable.Map()) => settings.getIn(['subscribers', 'account'], Immutable.Map()),
);

export const getMainMenuSettingsSelector =  createSelector(
  getSettings,
  (settings = Immutable.Map()) => settings.get('mainMenu', Immutable.List()),
);

export const getCurrencySelector = createSelector(
  getSettings,
  (settings = Immutable.Map()) => settings.getIn(['pricing', 'currency'], 'USD'),
);
export const getTimeZoneSelector = createSelector(
  getSettings,
  (settings = Immutable.Map()) => settings.getIn(['billrun', 'timezone'], Intl.DateTimeFormat().resolvedOptions().timeZone),
);

export const invoicingDaySelector = createSelector(
  getSettings,
  (settings = Immutable.Map()) => settings.getIn(['billrun','charging_day'], 'USD'),
);
export const usageTypesSelector = createSelector(
  getSettings,
  (settings = Immutable.Map()) => settings.get('usage_types', Immutable.List()),
);