import Immutable from 'immutable';
import { actions } from 'actions/authActions';
import moment from 'moment';


const defaultState = Immutable.Map({
  app: Immutable.Map(),
  user: Immutable.Map(),
})

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    
    case actions.APP_LOGIN: {
      const  { data } = action;
      // set the expiration time
      data.expires_time = moment().add(data.expires_in, 'seconds').unix();
      return state.setIn(['app'], Immutable.fromJS(data));
    }
    
    case actions.APP_LOGOUT: {
      return state.setIn(['app'], Immutable.Map());
    }

    case actions.USER_LOGIN: {
      const { data } = action;
      const validUntil = moment().add(data.expires_in, 'seconds').unix();
      const authData = {...data, expires_time: validUntil };
      return state.setIn(['user'], Immutable.fromJS(authData));
    }
    
    case actions.APP_LOGIN_ERROR: {
      return state.setIn(['app'], Immutable.Map({error: true}));
    }

    case actions.USER_LOGOUT: {
      return state.setIn(['user'], Immutable.Map());
    }

    case actions.USER_LOGIN_ERROR: {
      return state.setIn(['user'], Immutable.Map({error: true}));
    }

    default:
      return state;
  }
}

export default userReducer;
