const accountConfig = {
  display_in_details: [{
    field_name: 'customer_no',
  },{
    field_name: 'firstname',
  }, {
    field_name: 'lastname',
  }, {
    field_name: 'address',
  }, {
    field_name: 'zip_code',
  }, {
    field_name: 'city',
  }, {
    field_name: 'country',
  }, {
    field_name: 'email',
  }, {
    field_name: 'invoice_shipping_method',
  }],
};

export default accountConfig;
