import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'react-bootstrap';


const Content = (props) => (
  <> 
    <Container>
      <Row>
        { props.children }
      </Row>
    </Container>
  </>
);


Content.defaultProps = {
  children: null,
};

Content.propTypes = {
  children: PropTypes.element,
};


export default Content;
