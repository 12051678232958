import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';


const Loading = (props) => (
  <div>
    {props.children && props.children}
    {!props.children && (
      <FormattedMessage id="lbl.loading" />
    )}
  </div>
);

Loading.defaultProps = {
  children: null,
};

Loading.propTypes = {
  children: PropTypes.element,
};


export default Loading;
