import { combineReducers } from 'redux';
import progressIndicator from './progressIndicatorReducer';
import alerts from './alertsReducer';
import language from './languageReducer';
import menu from './menuReducer';
import modal from './modalReducer';


export default combineReducers({
  progressIndicator,
  alerts,
  language,
  menu,
  modal,
});
