import { useEffect, useCallback, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Container, Card } from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl'
import Immutable from 'immutable';
import download from 'downloadjs';
import Papa from 'papaparse';
import List from 'components/List';
import DisplayValue from 'components/DisplayValue';
import { SubscriberSwitcher, ElementToText } from 'components/Elements';
import ColumnValue from 'components/List/ColumnValue';
import { showWarning } from 'actions/alertsActions';
import { getSubscriberUsage, getExportSubscriberUsage } from 'actions/subscriberActions';
import {
  getSubscriberSidSelector,
  getSubscriberUsageSelector,
  getSubscriberMsisdnSelector,
} from 'selectors/subscriberSelector';
import { activeLanguageSelector } from 'selectors/applicationSelector';
import {
  invoicingDaySelector,
  usageTypesSelector,
  msisdnFieldSettingsSelector,
} from 'selectors/settingsSelectors';
import {
  getUsageTypesOptions,
  getBillingCyclesOptions,
  formatEntityValue,
} from 'common/Util';


const Usage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const lang = useSelector(activeLanguageSelector);
  const subscriberSid = useSelector(getSubscriberSidSelector);
  const subscriberUsage = useSelector(getSubscriberUsageSelector);
  const subscriberMsisdn = useSelector(getSubscriberMsisdnSelector);
  const invoicingDay = useSelector(invoicingDaySelector);
  const usageTypes = useSelector(usageTypesSelector);
  const msisdnFieldSettings = useSelector(msisdnFieldSettingsSelector);

  const getListData = useCallback(() => {
    if (subscriberSid === '') {
      return;
    }
    dispatch(getSubscriberUsage(subscriberSid))
    .catch((e) => {
      dispatch(showWarning(intl.formatMessage({
        id:`error.${e.message}`,
      }, {fields: e.fields}
      )));
    });
  }, [subscriberSid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (subscriberSid) {
      getListData();
    }
  }, [subscriberSid]); // eslint-disable-line react-hooks/exhaustive-deps

  const parseCountry = useCallback((value, config) => {
    let fieldKey = 'foreign.rate.params.country_name';
    if (lang === 'de') {
      fieldKey += '_de';
    }
    return config.getIn(['additionData', fieldKey], '');
  }, [lang]);

  const parseTo = useCallback((value, config) => {
    const usaget = config.getIn(['additionData', 'usaget'], '').toLocaleLowerCase();
    switch (usaget) {
      case 'sms': {
        const to = config.getIn(['additionData', 'uf.callednumber'], '');
        return formatEntityValue(msisdnFieldSettings, Immutable.Map({ a_number : to }));
      }
      case 'call': {  
        const to = config.getIn(['additionData', 'uf.callednumber'], '')  ;
        return formatEntityValue(msisdnFieldSettings, Immutable.Map({ a_number : to }));
      }
      case 'incoming_call': {
        const to = config.getIn(['additionData', 'uf.callingnumber'], '');
        return formatEntityValue(msisdnFieldSettings, Immutable.Map({ a_number : to }));
      }
      case 'data':
        return (
          <FormattedMessage id={`table.usage.to_data`} />
        );
      case 'flat':
        return (
          <DisplayValue type="date" value={config.getIn(['additionData', 'prorated_end_date'], '')} />
        );
      default:
        return '';
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const parseFrom = useCallback((value, config) => {
    const usaget = config.getIn(['additionData', 'usaget'], '').toLocaleLowerCase();
    switch (usaget) {
      case 'flat':
        return (
          <DisplayValue type="date" value={config.getIn(['additionData', 'prorated_start_date'], '')} />
        );
      default:
        return subscriberMsisdn;
    }
  }, [subscriberMsisdn]);

  const tableFields = useMemo(() => Immutable.fromJS([
    { id: 'urt', title: 'table.usage.urt', type:'datetime' },
    { id: 'from', title: 'table.usage.from', parser: parseFrom, additionData: {fields: ['usaget', 'prorated_start_date']} },
    { id: 'to', title: 'table.usage.to', parser: parseTo, additionData: {fields: ['usaget', 'uf.callednumber', 'uf.callingnumber', 'prorated_end_date']}},
    { id: 'country', title: 'table.usage.country', parser: parseCountry, additionData: {fields: ['foreign.rate.params.country_name', 'foreign.rate.params.country_name_de']}},
    { id: 'usaget', title: 'table.usage.type', type: 'usageType'},
    { id: 'usagev', title: 'table.usage.duration', type: 'usage', additionData: {fields: ['usaget'], _display_style: 'duration'}},
    { id: 'final_charge', title: 'table.usage.final_charge', type: 'price'},
  ]), [parseTo, parseFrom, parseCountry]);

  const tableFilters = useMemo(() => Immutable.fromJS([
    { id: 'type', multi: true, options: getUsageTypesOptions(usageTypes, intl) },
    { id: 'billrun', multi: false, options: getBillingCyclesOptions(invoicingDay) },
    { id: 'final_charge', options: {
      'charged': intl.formatMessage({id:'table.usage.only_chargeable'}),
      'uncharged': intl.formatMessage({id:'table.usage.not_chargeable'}),
    }, default: 'charged' }, 
  ]), [invoicingDay, usageTypes]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const downloadCsv = useCallback(() => {
    if (subscriberSid === '') {
      return;
    }
    dispatch(getExportSubscriberUsage(subscriberSid)).then(
      (usage = []) => {
        const fields = tableFields.map((tableField, idx) => tableField.get('id', idx)).toList().toJS();
        const labels = tableFields
          .map((tableField, idx) => tableField.get('title', idx))
          .map(id => intl.formatMessage({id}))
          .toList()
          .toJS();
        const fieldsConf = tableFields.reduce((acc, conf) => {
          return acc.set(conf.get('id'), conf);
        }, Immutable.Map());
        const data = Immutable.fromJS(usage)
          .map((row) => Immutable.List().withMutations((orderedList) => {
            fields.forEach((field) => {
              const fieldConf = fieldsConf.get(field, Immutable.Map());
              const rowElString = ReactDOMServer.renderToStaticMarkup(
                <ElementToText key={field} intl={intl}>
                  <ColumnValue data={row} config={fieldConf} />
                </ElementToText>
              );
              const strippedHtml = rowElString.replace(/<[^>]+>/g, '');
              orderedList.push(strippedHtml);
            });
          }))
          .toJS();
        const csv = Papa.unparse({ fields: labels, data });
        download(csv, `usage_${subscriberSid}.csv`, "text/csv");
      }
    )
  }, [subscriberSid, tableFields]); // eslint-disable-line react-hooks/exhaustive-deps

  const tableActions = useMemo(() => Immutable.fromJS([
    { type: 'refresh', onClick: getListData, enable: subscriberSid !== '' },
    { type: 'csv', onClick: downloadCsv, enable: subscriberSid !== '', actionStyle: 'primary', actionSize: 'sm', helpText: intl.formatMessage({id:'table.download_csv'})},
  ]), [subscriberSid, downloadCsv]); // eslint-disable-line react-hooks/exhaustive-deps

  const listHeader = (
    <Row>
      <Col xs={12} md={4}>
        <Card.Title className="field-height mb-0">
          <FormattedMessage id="field.selected_subscriber" />:
        </Card.Title>
      </Col>
      <Col xs={6} md={8}>
        <SubscriberSwitcher />
      </Col>
    </Row>
  );

  return ( 
    <Container>
      <Row>
        <Col>
          <Card className="p-3">
            <List
              entity='usage'
              data={subscriberUsage}
              fields={tableFields}
              filters={tableFilters}
              listActions={tableActions}
              reloadData={getListData}
              withPager={true}
              header={listHeader}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Usage;
