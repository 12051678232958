import { createSelector } from 'reselect';
import Immutable from 'immutable';
import isNumber from 'is-number';
import { getEntityFieldValue } from 'common/Util';
import { msisdnFieldSettingsSelector } from 'selectors/settingsSelectors';
import { isUnlimited } from 'common/Util';


const getSelectedSubscriber = (state, props) => {
  const sid = state.subscriber.get('selected', '');
  if (sid !== '') {
    return state.subscriber.get(sid);
  }
  return undefined;
}

const getSelectedSubscriberDetails = createSelector(
  getSelectedSubscriber,
  (subscriber = Immutable.Map()) => subscriber.get('details'),
);

export const getSubscriberUsageSelector = createSelector(
  getSelectedSubscriber,
  (subscriber = Immutable.Map()) => subscriber.get('usage', Immutable.List()),
);

export const isSubscriberDetailsExistsSelector = createSelector(
  getSelectedSubscriberDetails,
  (details = Immutable.Map()) => !details.isEmpty(),
);

export const getSubscriberSidSelector = createSelector(
  getSelectedSubscriberDetails,
  (details = Immutable.Map()) => details.get('sid', ''),
);

export const getSubscriberPlanSelector = createSelector(
  getSelectedSubscriberDetails,
  (subscriber = Immutable.Map()) => subscriber.get('plan_description', ''),
);

export const getSubscriberBriefUsageSelector = createSelector(
  getSelectedSubscriberDetails,
  (subscriber = Immutable.Map()) => {
    return subscriber
      .getIn(['include', 'services'], Immutable.List())
      .push(...subscriber.getIn(['services'], Immutable.List()))
      .map(services => services.getIn(['include', 'groups']), Immutable.Map())
      .filter(groups => Immutable.Map.isMap(groups))
      .reduce((acc, serviceGroups) => {
        const groupsWithUsage = serviceGroups
          .filter(serviceGroup => serviceGroup.has('usage'))
          .map(serviceGroup => {
            const usage = serviceGroup.get('usage', Immutable.Map());
            const usageTypes = serviceGroup.get('usage_types', Immutable.Map());
            const usageType = usageTypes.keySeq().first();
            const unit = usageTypes.first(Immutable.Map()).get('unit', '')
            return usage
              .set('usage_type', usageType)
              .set('unit', unit)
          });
        return acc.merge(groupsWithUsage);
      }, Immutable.Map())
    }
);

export const getSubscriberBriefUnlimitedUsageSelector = createSelector(
  getSubscriberBriefUsageSelector,
  (usage = Immutable.Map()) => usage.filter(usage => {
    const total = usage.get('total', '');
    return isUnlimited(total);
  })
);

export const getSubscriberOverBundleUsageSelector = createSelector(
  getSelectedSubscriberDetails,
  (details = Immutable.Map()) => details
    .get('over_bundle', Immutable.Map())
    .filter(over_bundle => (over_bundle.get('cost', 0) > 0))
);

export const getSubscriberBriefLimitedUsageSelector = createSelector(
  getSubscriberBriefUsageSelector,
  (usage = Immutable.Map()) => usage.filter(usage => {
    const total = usage.get('total', '');
    return isNumber(total) && total > 0;
  })
);


export const getSubscriberMsisdnSelector = createSelector(
  getSelectedSubscriberDetails,
  msisdnFieldSettingsSelector,
  (subscriber = Immutable.Map(), msisdnFieldConfig = Immutable.Map()) => 
    getEntityFieldValue(msisdnFieldConfig, subscriber)
);
