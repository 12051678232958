import React from 'react';
import Immutable from 'immutable';
import { FormattedMessage} from 'react-intl';


const UsageType = ({ value, config = Immutable.Map() }) => {
  const usageType = value.toLocaleLowerCase();
  return <FormattedMessage id={`usage_type.${usageType}`} defaultMessage={value} />
}

export default UsageType;

