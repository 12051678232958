import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl';
import momentDurationFormatSetup from 'moment-duration-format';// eslint-disable-line no-unused-vars
import Immutable from 'immutable';
import { ThemeProvider } from 'react-bootstrap';
import flat from 'flat';
import Routes from 'Routes';
import ProgressIndicator from 'components/ProgressIndicator';
import Alerts from 'components/Alerts';
import ReduxFormModal from 'components/ReduxFormModal';
import ReduxConfirmModal from 'components/ReduxConfirmModal';
import {
  activeLanguageSelector,
} from 'selectors/applicationSelector';
import { isAppAuthorizedSelector } from 'selectors/authSelector';
import {
  getLanguageSettingsSelector,
  getMainMenuSettingsSelector,
  getSubscriberSettingsSelector,
  getAccountSettingsSelector,
  getTimeZoneSelector,
} from 'selectors/settingsSelectors';
import { setLanguage } from 'actions/applicationActions';
import { getLanguageDir, getDefaultLanguage, getEnabledLanguages, getLanguageCode } from 'common/Util';
import { AppAuthWrapper } from 'components/Auth';
import { updateSetting, getSettings } from 'actions/settingsActions';


const App = () => {

  const [isConfigLoaded, setConfigLoaded] = useState(false);
  const dispatch = useDispatch();
  const isAppAuthorized = useSelector(isAppAuthorizedSelector);
  const lang = useSelector(activeLanguageSelector);
  const languageConfig = useSelector(getLanguageSettingsSelector);
  const mainMenuConfig = useSelector(getMainMenuSettingsSelector);
  const subscriberConfig = useSelector(getSubscriberSettingsSelector);
  const accountConfig = useSelector(getAccountSettingsSelector);
  const timezone = useSelector(getTimeZoneSelector);

  useEffect(() => {
    // always get the system because it saved in root and its not possible to check if already loaded
    const systemSettings = require('config/system').default;
    for (const category in systemSettings) {
      dispatch(updateSetting(category, systemSettings[category]));
    }
    if (mainMenuConfig.isEmpty()) {
      const mainMenuSettings = require('config/mainMenu').default;
      dispatch(updateSetting('mainMenu', mainMenuSettings));
    }
    if (subscriberConfig.isEmpty()) {
      const subscriberSettings = require('config/subscriber').default;
      dispatch(updateSetting('subscribers', subscriberSettings, 'subscriber'));
    }
    if (accountConfig.isEmpty()) {
      const accountSettings = require('config/account').default;
      dispatch(updateSetting('subscribers', accountSettings, 'account'));
    }
    if (languageConfig.isEmpty()) {
      const languageSettings = require('config/language').default;
      dispatch(updateSetting('language', languageSettings));
    }
    if (isAppAuthorized) {
      // get requires settings categories from billing
      const categories = [
        'usage_types',
        'pricing.currency',
        'subscribers.account.fields',
        'subscribers.subscriber.fields',
        'billrun.charging_day',
        'billrun.timezone'
      ];
      dispatch(getSettings(categories)).then(() => {
        setConfigLoaded(true);
      });
    }
  }, [isAppAuthorized]); // eslint-disable-line react-hooks/exhaustive-deps

  if (languageConfig.isEmpty()) {
    return (<div>Loading...</div>);
  }

  const defaultLang = getDefaultLanguage(languageConfig);
  if (!lang) {
    dispatch(setLanguage(defaultLang));
    return (<div>Loading...</div>);
  }
  const enabledLanguages = getEnabledLanguages(languageConfig);
  if (enabledLanguages.isEmpty()) {
    return (<div>Loading...</div>);
  }
  
  // Create lang flat object with default translation of default lang
  const tmpDefaultLan = 'en'; //temporary use EN as base lang instead of default, will be replaced by: defaultLang
  let messages = Immutable.Map();
  const defaultLangMessages = require(`lang/${tmpDefaultLan}`).default;
  if (lang === tmpDefaultLan) {
    messages = defaultLangMessages;
  } else {
    const activeLangMessages = require(`lang/${lang}`).default;
    messages = Immutable.mergeDeep(defaultLangMessages, activeLangMessages);
  } 
  messages = flat(messages);

  const appLangDir = getLanguageDir(lang, languageConfig)
  document.documentElement.dir = appLangDir;
  document.documentElement.lang = lang;
  document.documentElement.class = lang;

  // if(appLangDir === 'rtl'){
  //   import('bootstrap/dist/css/bootstrap.rtl.min.css')
  // } else {
  //   import('bootstrap/dist/css/bootstrap.min.css')
  // }
  const langCode = getLanguageCode(lang, languageConfig);
  const langDefaultCode = getLanguageCode(defaultLang, languageConfig);

  return (
    <IntlProvider messages={messages} locale={langCode} defaultLocale={langDefaultCode} timeZone={timezone}>
      <ThemeProvider dir={appLangDir}>
        <div>
          <ProgressIndicator />
          <ReduxFormModal />
          <ReduxConfirmModal />
          <Alerts />
            <AppAuthWrapper>
            {isConfigLoaded && (
              <Routes />
            )}
            </AppAuthWrapper>
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
