import React from 'react';
import Immutable from 'immutable';
import { FormattedNumber } from 'react-intl';


const Number = ({ value, config = Immutable.Map() }) => {
  if (!isNaN(value) && value > 0) {
    return (
      <FormattedNumber value={value} />
    );
  }
  return value;
}

export default Number;
