import {
  apiBillRun,
  apiBillRunApp,
  // apiBillRunSuccessHandler,
  apiBillRunErrorHandler,
} from 'common/Api';
import {
  appLoginQuery,
  userLoginQuery,
  userSignUpQuery,
  resetPasswordQuery,
  requestResetPasswordQuery,
} from 'common/ApiQueries';
import { clearAppStorage } from './settingsActions';


export const actions = {
  APP_LOGIN: 'APP_LOGIN',
  APP_LOGOUT: 'APP_LOGOUT',
  APP_LOGIN_ERROR: 'APP_LOGIN_ERROR',
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_LOGOUT: 'USER_LOGOUT',
};


const loginAppSuccess = data => ({
  type: actions.APP_LOGIN,
  data,
});

const loginAppError = data => ({
  type: actions.APP_LOGIN_ERROR,
  data,
});

const logoutAppSuccess = () => ({
  type: actions.APP_LOGOUT,
});

const loginUserSuccess = data => ({
  type: actions.USER_LOGIN,
  data,
});

const loginUserError = data => ({
  type: actions.USER_LOGIN_ERROR,
  data,
});

const logoutUserSuccess = () => ({
  type: actions.USER_LOGOUT,
});

export const resetPassword = (username, password, token) => dispatch => {
  const query = resetPasswordQuery(username, password, token);
  return dispatch(apiBillRunApp(query))
    .then((success) => Promise.resolve())
    .catch((error) => {
      const responseErrorCode = error?.error?.code || 0;
      if (responseErrorCode > 4800 && responseErrorCode < 4811) {
        return Promise.reject('pass_strength');
      }
      return Promise.reject(responseErrorCode);
    });
};

export const requestResetPassword = (username, password, token) => dispatch => {
  const query = requestResetPasswordQuery(username, password, token);
  return dispatch(apiBillRunApp(query))
    .then((success) => Promise.resolve())
    .catch((error) => Promise.reject());
};

export const userSignUp = (username, password, token) => dispatch => {
  const query = userSignUpQuery(username, password, token);
  return dispatch(apiBillRunApp(query))
    .then((success) => Promise.resolve())
    .catch((error) => {
      const responseErrorCode = error?.error?.code || 0;
      if (responseErrorCode > 4800 && responseErrorCode < 4811) {
        return Promise.reject('pass_strength');
      }
      return Promise.reject(responseErrorCode);
    });};

export const userDoLogin = (username, password, intl) => dispatch => {
  const query = userLoginQuery(username, password);
  return apiBillRun(dispatch, query)
    .then((success) =>  dispatch(loginUserSuccess(success.data)))
    .catch((error) => {
      dispatch(loginUserError(error));
      return dispatch(apiBillRunErrorHandler({message: intl.formatMessage({id:'error.user_login_error'})}));
    });
};

export const userDoLogout = () => (dispatch) => {
  clearAppStorage();
  return dispatch(logoutUserSuccess());
}


export const appDoLogin = () => (dispatch) => {
  const query = appLoginQuery();
  return apiBillRun(dispatch, query)
    .then((success) => {
      dispatch(loginAppSuccess(success.data));
      return success;
    })
    .catch((error) => {
      dispatch(apiBillRunErrorHandler(error));
      dispatch(loginAppError(error));
      return error;
    });
}

export const appDoLoginOut = () => (dispatch) => {
  return dispatch(logoutAppSuccess())
}


