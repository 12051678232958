import Immutable from 'immutable';
import { Card, Col, Row, Container, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { AccountSubscribers } from 'components/Elements';
import ChangePassword from 'components/ChangePassword';
import Filed from 'components/Fields'
import { getAccountSettingsSelector } from 'selectors/settingsSelectors';
import {
  accountDetailsSelector,
  getAccountSubscribersSelector
} from 'selectors/accountSelectors';
import { showFormModal } from 'actions/modalActions';
import { updateAccountPassword } from 'actions/accountActions';
import {
  setFormModalError,
} from 'actions/modalActions';
import { getEntityFieldValue } from 'common/Util';


const MyAccount = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const subscribers = useSelector(getAccountSubscribersSelector);
  const account = useSelector(accountDetailsSelector);
  const accountConfig = useSelector(getAccountSettingsSelector);
  const accountDisplayFields = accountConfig.get('display_in_details', Immutable.List());

  const onOkChangePassword = (editedItem) => {
    const password1 = editedItem.get('password1', '');
    const password2 = editedItem.get('password2', '');
    const oldPassword = editedItem.get('passwordOld', '');
    if (password1 === '') {
      const fieldLabe = intl.formatMessage({id: 'field.new_password.password1'});
      const message = intl.formatMessage({id: 'error.missing_required_field'}, {field: fieldLabe});
      dispatch(setFormModalError('pass', message));
      return false;
    }
    if (password2 === '') {
      const fieldLabe = intl.formatMessage({id: 'field.new_password.password2'});
      const message = intl.formatMessage({id: 'error.missing_required_field'}, {field: fieldLabe});
      dispatch(setFormModalError('pass', message));
      return false;
    }
    if (password1 !== password2) {
      const message = intl.formatMessage({id: 'error.pass_not_match'});
      dispatch(setFormModalError('pass', message));
      return false;
    }
    const successMessage = intl.formatMessage({id: 'lbl.reset_password_success'});
    return dispatch(updateAccountPassword(oldPassword, password1, successMessage));
  };

  const onChangePassword = () => {
    const config = {
      title: intl.formatMessage({id:'lbl.change_password'}),
      onOk: onOkChangePassword,
    };
    return dispatch(showFormModal(Immutable.Map(), ChangePassword, config));
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card className="p-3">
            <Card.Title className="mb-3">
              <FormattedMessage id="lbl.account_details" />
            </Card.Title>
            <Form>
              {accountDisplayFields.map((config, idx) => (
                <Form.Group as={Row} className="mb-3" key={config.get('field_name', idx)}>
                  <Form.Label column sm={2}>
                    <FormattedMessage id={`field.account.${config.get('field_name', '')}`} />
                  </Form.Label>
                  <Col sm={10}>
                    <Filed disabled={true} value={getEntityFieldValue(config, account)} />
                  </Col>
                </Form.Group>
              ))}
              <Form.Group as={Row} className='mb-3' key='change-password'>
                <Form.Label column sm={2}></Form.Label>
                <Col sm={10}>
                  <Button variant='primary' onClick={onChangePassword}>
                    <FormattedMessage id='btn.change_password' />
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Card className="p-3">
            <Card.Title className="mb-3">
              <FormattedMessage id="lbl.account_subscribers" values={{ count: subscribers.size }} />
            </Card.Title>
            <AccountSubscribers subscribers={subscribers} />
          </Card>
        </Col>
      </Row>

    </Container>
  );
};

export default MyAccount;
