import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Immutable from 'immutable';
import { Row, Col } from 'react-bootstrap';
import Pagination from '@vlsergey/react-bootstrap-pagination';
import isNumber from 'is-number';
import Filed from 'components/Fields';
import {
  getListTotalPagesSelector,
  getListCurrentPageSelector,
  getListSizeSelector,
} from 'selectors/listSelectors';
import {
  setCurrentPages,
  setSize,
  removeSize,
} from 'actions/listActions';
import { getConfig } from 'common/Util';

const sizeOptions = getConfig('listSizes', Immutable.List()).map(size => ({
  value: size,
  label: size,
}))
.toArray()

const Pager = ({ entity }) => {
  const dispatch = useDispatch();
  const totalPages = useSelector((state) => getListTotalPagesSelector(state, entity));
  const currentPage = useSelector((state) => getListCurrentPageSelector(state, entity));
  const listSize = useSelector((state) => getListSizeSelector(state, entity));

  const handleChange = useCallback((e) => {
    const { value } = e.target;
    if (value >= 0 && value < totalPages) {
      dispatch(setCurrentPages(entity, value + 1));
    }
  },[dispatch, entity, totalPages]);

  const onSelectSize = useCallback((value) => {
    if (value === null) {
      dispatch(removeSize(entity, value));
    } else {
      dispatch(setSize(entity, value));
    }
    dispatch(setCurrentPages(entity, 1));
  }, [dispatch, entity]);

  const selectedSizeOption = useMemo(() => {
    const selectedOption = sizeOptions.filter(option => option.value === listSize);
    return (selectedOption.length > 0) ? selectedOption[0] : null
  }, [listSize]);

  if (!isNumber(totalPages) || totalPages < 1) {
    return null;
  }

  return (
    <Row className="list-pagination">
      <Col sm="10" className="text-start">
      <Pagination value={currentPage - 1} totalPages={totalPages} onChange={handleChange} />
      </Col>
      <Col sm="2" className="text-end">
        <Filed
          fieldType="select"
          clearable={false}
          value={selectedSizeOption}
          onChange={onSelectSize}
          options={sizeOptions}
        />
      </Col>
    </Row>
  );
}

export default Pager;