import Immutable from 'immutable';
import { actions } from 'actions/accountActions';
import { actions as authActions } from 'actions/authActions';
import isNumber from 'is-number';


const defaultState = Immutable.Map();

const accountReducer = (state = defaultState, action) => {
  switch (action.type) {

    case actions.GOT_ACCOUNT_DETAILS: {
      const  { data } = action;
      const details = data?.details || defaultState;
      return Immutable.fromJS(details);
    }

    case actions.GOT_ACCOUNT_INVOICES: {
      const  { data } = action;
      const details = data?.details || Immutable.List();
      const invoices = Immutable.fromJS(details);
      return state.set('invoices', invoices);
    }

    case actions.GOT_ACCOUNT_OUTSTANDING: {
      const  { data } = action;
      const details = data?.details || Immutable.List();
      const outstanding = Immutable.fromJS(details);
      if (outstanding.isEmpty()) {
        return state.set('outstanding', '');
      }
      let total = outstanding.get('total','');
      total = isNumber(total) ? parseFloat(total) : total;
      return state.set('outstanding', total);
    }
 
    case actions.GOT_ACCOUNT_DEBT: {
      const  { data } = action;
      const details = data?.details || Immutable.List();
      const debt = Immutable.fromJS(details);
      if (debt.isEmpty()) {
        return state.set('debt', '');
      }
      return state.set('debt', debt.first(Immutable.Map()).get('total',''));
    }

    case actions.GOT_ACCOUNT_CHARGES: {
      const  { data } = action;
      const details = data?.details || Immutable.List();
      const charges = Immutable.fromJS(details);
      return state.set('charges', charges);
    }

    case actions.GOT_ACCOUNT_STATEMENTS: {
      const  { data } = action;
      const details = data?.details || Immutable.List();
      const statements = Immutable.fromJS(details);
      return state.set('statements', statements);
    }

    case authActions.USER_LOGOUT:  {
      return defaultState;
    }
    
    default:
      return state;
  }
}

export default accountReducer;
