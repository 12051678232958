import React from 'react';
import Immutable from 'immutable';
import moment from 'moment';
import { FormattedDate } from 'react-intl';


const Date = ({ value, config = Immutable.Map() }) => {
  let date = value;
  if (Immutable.Map.isMap(value) && value.has('sec')) {
    date = moment.unix(value.get('sec', 0));
  }
  if (typeof value === 'string' && value.length > 0) {
    date = moment(value);
  }
  if (moment.isMoment(date) && date.isValid()) {
    return (
      <FormattedDate value={date} />
    );
  }
  return '';
}

export default Date;

