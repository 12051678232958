
import { useQuery } from 'common/Hooks';
import { RequestNew, SetNew, Result } from 'components/ResetPassword'


const ResetPassword = () => {
  const { token = '', username = '', status = '' } = useQuery();
  // First screen to fill username and request mail with link
  if (token === '' && username === '' && status === ''  )  {
    return (<RequestNew />);
  }
  // Second screen to select new password
  if (token !== '' && username !== '' && status === '') {
    return (
      <SetNew token={token} username={username}/>
    );
  }
  // Default screen to show Error or Success details
  return <Result status={status} />;
}

export default ResetPassword;