const de = {
  page: {
    title: {
      home: "Startseite",
      login: "Anmelden",
      myAccount: "Meine Daten",
      invoices: "Rechnungen",
      usage: "Verbrauch",
      charges: "Gebühren",
      statement: "Kontostand",
      signup: "Anmelden",
      reset_password: "Passwort zurücksetzen",
      404: "Seite nicht gefunden (404)",
    },
    details: {
      404: 'Bitte um Entschuldigung, diese Seite konnte nicht gefunden werden.',
    }
  },
  usage_type: {
    call: 'Anruf',
    incoming_call: 'Eingehender Anruf',
    sms: 'SMS',
    data: 'Mobile Daten',
    charge: 'Gebühr',
    flat: 'Gebühr',
    discount: 'Gutschrift',
    credit: 'Gutschrift',
    mms: 'MMS',
  },
  lbl: {
    confirm_discard: 'Schliessen ohne zu speichern?',
    change_password: 'Passwort ändern',
    version: "Version",
    loading:"Wird geladen...",
    logout: "Abmelden",
    login: "Anmelden",
    hello_account: "{icon} {name}",
    plan_usage: "Mein Verbrauch",
    next_invoicing_date: "Nächster Rechnungszyklus",
    plan_unlimited_usage: "Mein unbegrenzter Verbrauch",
    out_group_usage: "Mehrverbrauch",
    my_plan: "Mein Abo",
    plan: "Abo",
    account_details: "Kundendetails",
    account_subscribers: "Abodetails ({count})",
    used_of: "{used} von {of}",
    minutes: "Minuten",
    days_cycle_left: "Verbleibende Tage",
    select_subscriber: "Rufnummer auswählen",
    password: {
      short: "Zu kurz",
      score1: "Sehr schwach",
      score2: "Schwach",
      score3: "Mässig",
      score4: "Gut",
      score5: "Stark",
      uppercase: "Grossbuchstaben",
      lowercase: "Kleinbuchstaben",
      number: "Zahlen",
      symbols: "Sonderzeichen",
      hint: "Das Passwort muss {pass_len} Zeichen sowie folgendes enthalten: {requirements}",
    },
    request_reset_password_success: "Ein Link um das Passwort zurück zu setzen, wurde per Email versendet.",
    reset_password_success: "Das Passwort wurde erfolgreich geändert.",
  },
  field: {
    captcha: 'Verifizierungscode',
    selected_subscriber: 'Nutzer',
    login_form: {
      user_name: 'Benutzername',
      user_password: 'Passwort',
      forgot_password: 'Passwort vergessen ?',
    },
    new_password: {
      user_name: 'Benutzername',
      password1: 'Neues Passwort',
      password2: 'Passwort bestätigen',
    },
    subscriber: {
      full_name: "Name",
      a_number: "Rufnummer",
      sid: "Reference",
      first_name: 'Vorname',
      last_name: 'Nachname',
      plan: 'Abo',
      plan_description: 'Abo',
    },
    account: {
      customer_no: 'Kundennr',
      firstname: 'Vorname',
      lastname: 'Nachname',
      address: 'Adresse',
      country: 'Land',
      city: 'Ort',
      email: 'Email',
      zip_code: 'PLZ',
      phone: 'Telefon',
      invoice_shipping_method: 'Rechnungsversand',
    },
  },
  table: {
    filter: 'Filter',
    refresh: 'Aktualisieren',
    row_actions: 'Aktion',
    download: 'Herunterladen',
    download_csv: 'CSV Herunterladen',
    empty_header: 'Belastung',
    statement: {
      total_outstanding: 'Saldo auf Kundenkonto',
      date: 'Datum',
      description	: 'Beschreibung',
      total: 'Betrag (CHF)',
      credit: 'Gutschrift',
      debit: 'Belastung',
      type_invoice: 'Rechnung',
      type_payment: 'Zahlung vom',
      description_customer_balance: "Kontostand vom",
    },
    usage: {
      urt: 'Datum',
      from: 'Von',
      to: 'Nach',
      country: 'Land',
      type: 'Anruftyp',
      duration: 'Dauer/Ereignisse',
      aid: 'Kunden Nr.',
      sid: 'Abo Nr.',
      plan: 'Abo',
      billrun: 'Rechnung',
      final_charge: 'Betrag (CHF)',
      to_data: 'Mobile Daten',
      only_chargeable: 'Gebührenpflichtig',
      not_chargeable: 'Kostenlos',
    },
    charge: {
      date: 'Startdatum',
      due_date: 'Enddatum',
      description	: 'Beschreibung',
      quantity	: 'Anzahl',
      total: 'Preis (CHF)',
      billrun: 'Rechnung',
    },
    invoice: {
      invoice: 'Rechnung',
      customer: 'Kunde',
      invoice_number: 'Rechnungsnummer',
      due_date: 'Zahlbar bis',
      method: 'Zahlungsmethode',
      date: 'Datum',
      charge: 'Betrag (CHF)',
      paid: 'Bezahlt',
    },
  },
  placeholder: {
    select: 'Auswählen...',
    captcha: 'Bitte Verifizierungscode eingeben...',
    login_form: {
      user_name: 'Bitte Kundennummer eingeben...',
      user_password: 'Bitte Passwort eingeben...',
    },
    new_password: {
      password: 'Bitte neues Passwort eingeben...',
      reenter_password: 'Bitte neues Passwort erneut eingeben...',
    },
    subscriber_switcher: {
      select_subscriber: 'Rufnummer auswählen...',
    }
  },
  btn: {
    yes: "Ja",
    no: "Nein",
    ok: "OK",
    save: "Speichern",
    cancel: "Abbrechen",
    filter: 'Filter',
    signup: 'Anmelden',
    login: "Anmelden",
    reset: 'Zurücksetzen',
    send: 'Senden',
    change_password: 'Passwort ändern'
  },
  error: {
    pass_not_match: "Passwörter stimmen nicht überein",
    missing_required_field: "Fehlendes Pflichtfeld: {field}",
    general: "Ups, etwas ist schief gelaufen... bitte später noch einmal versuchen",
    app_login: "Die Verbindung zum App kann nicht hergestellt werden.",
    user_login_error: "Falscher Benutzername oder Passwort.",
    missing_required_fields: "Fehlende Pflichtfelder: {fields}",
    signup_fail: "Ups, etwas ist schief gelaufen... bitte später noch einmal versuchen",
    request_reset_password_fail: "Ups, etwas ist schief gelaufen... bitte später noch einmal versuchen",
    reset_password_fail: "Ups, etwas ist schief gelaufen... bitte später noch einmal versuchen",
    reset_password_strength: "Ungültiges neues Passwort",
    captcha_invalid: "Ungültiger Verifizierungscode"
  },
  usage_groups: {
    "7ACHT_YOUNG_DATA_BUNDLE_HOMENETWORK_GROUP": "Unlimitierte Daten im Heimnetz",
    "7ACHT_YOUNG_DATA_BUNDLE_EU_GROUP": "Daten in der EU",
    "7ACHT_YOUNG_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimitierte Anrufe zu FL Mobile",
    "7ACHT_YOUNG_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Anrufe im Heimnetz & EU",
    "7ACHT_YOUNG_CALL_BUNDLE_EU_TO_CH_GROUP": "Anrufe in der EU nach CH",
    "7ACHT_YOUNG_INCOMING_CALLS_GROUP": "Unlimitierte ankommende Anrufe in der EU",
    "7ACHT_YOUNG_OUTGOING_SMS_GROUP": "Unlimitierte SMS im Heimnetz & EU",
    "7ACHT_19_DATA_BUNDLE_GROUP": "Daten im Heimnetz & EU",
    "7ACHT_19_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimitierte Anrufe zu FL Mobile",
    "7ACHT_19_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Anrufe im Heimnetz & EU",
    "7ACHT_19_CALL_BUNDLE_EU_TO_CH_GROUP": "Anrufe in der EU nach CH",
    "7ACHT_19_INCOMING_CALLS_GROUP": "Unlimitierte ankommende Anrufe in der EU",
    "7ACHT_19_OUTGOING_SMS_GROUP": "Unlimitierte SMS im Heimnetz & EU",
    "7ACHT_29_DATA_BUNDLE_GROUP": "Daten in der EU",
    "7ACHT_29_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimitierte Anrufe zu FL Mobile",
    "7ACHT_29_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Anrufe im Heimnetz & EU",
    "7ACHT_29_CALL_BUNDLE_EU_TO_CH_GROUP": "Anrufe in der EU nach CH",
    "7ACHT_29_INCOMING_CALLS_GROUP": "Unlimitierte ankommende Anrufe in der EU",
    "7ACHT_29_OUTGOING_SMS_GROUP": "Unlimitierte SMS im Heimnetz & EU",
    "7ACHT_49_DATA_BUNDLE_GROUP": "Daten in der EU",
    "7ACHT_49_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimitierte Anrufe zu FL Mobile",
    "7ACHT_49_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Anrufe im Heimnetz & EU",
    "7ACHT_49_CALL_BUNDLE_EU_TO_CH_GROUP": "Anrufe in der EU nach CH",
    "7ACHT_49_INCOMING_CALLS_GROUP": "Unlimitierte ankommende Anrufe in der EU",
    "7ACHT_49_OUTGOING_SMS_GROUP": "Unlimitierte SMS im Heimnetz & EU",
    "7ACHT_69_DATA_BUNDLE_HOMENETWORK_GROUP": "Unlimitierte Daten im Heimnetz",
    "7ACHT_69_DATA_BUNDLE_EU_GROUP": "Daten in der EU",
    "7ACHT_69_CALL_BUNDLE_EU_TO_CH_GROUP": "Unlimitierte Anrufe zu FL Mobile",
    "7ACHT_69_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimitierte Minuten im Heimnetz & EU",
    "7ACHT_69_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Anrufe in der EU nach CH",
    "7ACHT_69_INCOMING_CALLS_GROUP": "Unlimitierte ankommende Anrufe in der EU",
    "7ACHT_69_OUTGOING_SMS_GROUP": "Unlimitierte SMS im Heimnetz & EU",
    "7ACHT_99_DATA_BUNDLE_HOMENETWORK_GROUP": "Unlimitierte Daten im Heimnetz",
    "7ACHT_99_DATA_BUNDLE_EU_GROUP": "Daten in der EU",
    "7ACHT_99_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimitierte Anrufe zu FL Mobile",
    "7ACHT_99_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Unlimitierte Minuten im Heimnetz & EU",
    "7ACHT_99_CALL_BUNDLE_EU_TO_CH_GROUP": "Anrufe in der EU nach CH",
    "7ACHT_99_INCOMING_CALLS_GROUP": "Unlimitierte ankommende Anrufe in der EU",
    "7ACHT_99_OUTGOING_SMS_GROUP": "Unlimitierte SMS im Heimnetz & EU",
    "7ACHT_DATA_DATA_BUNDLE_HOMENETWORK_GROUP": "Unlimitierte Daten im Heimnetz",
    "7ACHT_49_DATA_BUNDLE_HOMENETWORK_GROUP": "Daten im Heimnetz",
    "7ACHT_29_DATA_BUNDLE_HOMENETWORK_GROUP": "Daten im Heimnetz",
    "7ACHT_99_DATA_BUNDLE_USA_CAN": "Daten in der USA & Kanada",
  },
};

export default de;
