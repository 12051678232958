import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import Filed from 'components/Fields';
import { PasswordConfirm } from 'components/Elements';
import { resetPassword } from 'actions/authActions';
import { showSuccess, showDanger } from 'actions/alertsActions';
import { getConfig, passwordHint } from 'common/Util';


const SetNew = ({ token = '', username = ''}) => {
  let history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [passwordStrengthError, setPasswordStrengthError] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [captchaCode, setCaptchaCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isInvalidCode, setIsInvalidCode] = useState(false);

  const minLength = getConfig('passwordMinLength', 6);
  const passwordMatched = password1 === password2 && password1 !== '';
  const allowSubmit = password1.length >= minLength && passwordMatched && verificationCode !== '';

  const passHintState = classNames('text-center mb-3', {
    'color-1': !passwordStrengthError,
    'text-danger': passwordStrengthError,
  });

  const onChangeCaptchaCode = (code) => {
    setVerificationCode(code);
    setIsInvalidCode(false);
  }

  const onResetCaptchaCode = (code) => {
    setCaptchaCode(code);
    setIsInvalidCode(false);
  }

  const onChangePassword1 = (password) => {
    setPasswordStrengthError(false);
    setPassword1(password);
  }
  
  const onChangePassword2 = (password) => {
    setPasswordStrengthError(false);
    setPassword2(password);
  }
  
  const isValidResetRequest = () => {
    if (password1 !== password2) {
      return false;
    }
    if (captchaCode !== verificationCode) {
      setIsInvalidCode(true);
      return false;
    }
    return true;
  }

  const onSubmitReset = (e) => {
    e.preventDefault();
    if (isValidResetRequest()) {
      dispatch(resetPassword(username, password1, token))
        .then((s) => { 
          dispatch(showSuccess(intl.formatMessage({ id: 'lbl.reset_password_success' })));
          history.push("/login");
        })
        .catch((e) => {
          if (e === 'pass_strength') {
            dispatch(showDanger(intl.formatMessage({ id: 'error.reset_password_strength' })));
            setPasswordStrengthError(true);
          } else {
            history.push({ pathname: '/reset-password', search: "?status=reset_error" });
          }
        });
    }
  }

  return (
    <Container>
    <Row>
      <Col>
        <p><FormattedMessage id="page.details.forgot_password" /></p>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form>
          <PasswordConfirm
            password1={password1}
            password2={password2}
            onPasswordChange={onChangePassword1}
            onConfirmPasswordChange={onChangePassword2}
          />
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="4" className="text-end">
              <FormattedMessage id="field.captcha" />
            </Form.Label>
            <Col sm="6">
              <Filed
                fieldType="captcha"
                value={verificationCode}
                onChange={onChangeCaptchaCode}
                onChangeCode={onResetCaptchaCode}
                hasError={isInvalidCode}
              />
            </Col>
          </Form.Group>
          <Col  sm="12"className={passHintState}>
            <Col sm={{ span: 8, offset: 2 }}>
              <small>* {passwordHint(intl)}</small>
            </Col>
          </Col>
          <Col sm="12" className="text-center">
            <Button type="submit" variant="success" onClick={onSubmitReset} className="text-end" disabled={!allowSubmit}>
              <FormattedMessage id="btn.reset" />
            </Button>
          </Col>
        </Form>
      </Col>
    </Row>
  </Container>
  );
}

export default SetNew;

