import { createStore, applyMiddleware, compose } from 'redux';
import Immutable from 'immutable';
import thunkMiddleware from 'redux-thunk';
import persistState from 'redux-localstorage';
import rootReducer from './reducers';
import { getConfig } from './common/Util';

const initialState = {};

const createLocalStorageConfig = () => ({
  key: getConfig(['env', 'APP_VERSION'], '0.0.1'),
  slicer: () => state => ({
    application: {
      language: state.application.language,
      menu: state.application.menu,
    },
    user: state.user,
    auth: state.auth,
  }),
  deserialize: (serializedData) => {
    const subReducersDataKeys = ['application'];
    const parseData = JSON.parse(serializedData);
    if (parseData) {
      Object.keys(parseData).forEach((key) => {
        if (subReducersDataKeys.includes(key)) {
          Object.keys(parseData[key]).forEach((entityListKey) => {
            parseData[key][entityListKey] = Immutable.fromJS(parseData[key][entityListKey]);
          });
        } else {
          parseData[key] = Immutable.fromJS(parseData[key]);
        }
      });
    }
    return parseData;
  },
})

const createComposedEnhancers = () => {
  const localStorageConfig = createLocalStorageConfig();
  if (process.env.NODE_ENV === "development") {
    const DevTools = require('./components/DevTools').default;
    return compose(
      persistState(null, localStorageConfig),
      // Middleware you want to use in development:
      applyMiddleware(thunkMiddleware),
      // Required! Enable Redux DevTools with the monitors you chose
      DevTools.instrument()
    );
  }
  return compose(
    persistState(null, localStorageConfig),
    applyMiddleware(thunkMiddleware),
  );
}

const store = createStore(
  rootReducer,
  initialState,
  createComposedEnhancers(),
);

export default store;