import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  getListSortValueSelector,
} from 'selectors/listSelectors';
import {
  setSort,
  toggleSort,
} from 'actions/listActions';

const HeaderField = ({ entity, field }) => {
  const sort = useSelector((state) => getListSortValueSelector(state, entity, field.get('id', '')));
  const dispatch = useDispatch();

  const fieldName = field.get('id', '');
  const style = classNames({
    [field.get('type', '')]: field.has('type', 'text'),
  });
  const sortable = field.get('sort', false);
  const defaultSort = field.get('defaultSort', 0);
  if (sortable && typeof sort === 'undefined' && [1, -1].includes(defaultSort)) {
    dispatch(setSort(entity,fieldName, defaultSort));
  }
  const sortStyle = classNames('bi', {
    'bi-sort-up': sort === -1,
    'bi-sort-down-alt': sort === 1,
  });
  const onSort = () => {
    dispatch(toggleSort(entity, fieldName));
  }
  return (
    <th className={style}>
      {sortable && (
        <Button variant="link" onClick={onSort} className="pt-0 pb-0 ps-0 pe-1">
          <i className={sortStyle}/>
        </Button>
      )}
      <FormattedMessage id={field.get('title', 'table.empty_header')} />
    </th>
  )
}

export default HeaderField;