import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { LanguageSwitcher } from 'components/Elements'
import MainMenu from 'components/Layout/Menu/MainMenu';
import Header from 'components/Layout/Header';
import Content from 'components/Layout/Content';
import Footer from 'components/Layout/Footer';
import { useTitle } from 'common/Hooks';
import { mainMenuStateSelector } from 'selectors/applicationSelector';
import { setMainMenuState } from 'actions/applicationActions';


const Base = (props) => {
  const { mainMenuState, title } = props;
  useTitle(title);

  const switchMainMenuState = () => {
    props.dispatch(setMainMenuState(!mainMenuState));
  }

  const sidebarClass = classNames({
    'active': mainMenuState,
  });

  const menuBtnClass = classNames('bi bi-lg' , {
    'bi-filter-left': mainMenuState,
    'bi-list': !mainMenuState,
  });

  return(
    <div id="app-wrapper">
      <nav id="app-sidebar" className={sidebarClass}>
        <div className="sidebar-header">
          <Button variant="link" className="shadow-none d-none d-md-inline-block px-3 py-1" onClick={switchMainMenuState}>
            <i className={menuBtnClass} /> 
          </Button>
          <span className="ms-2 d-inline-block">
            <LanguageSwitcher />
          </span>
        </div>
        <MainMenu />
      </nav>

      <div id="app-content" className="mb-3">
        <Header />
        { title !== '' && (
          <div className="container">
            <h2 className="mt-2">{title}</h2>
            <hr className="mb-3"/>
          </div>
        )}
        <Content>
          { props.children }
        </Content>
      </div>
      <Footer />
    </div>
  );
}


Base.defaultProps = {
  children: null,
  mainMenuState: true,
  title: '',
};

Base.propTypes = {
  children: PropTypes.element,
  mainMenuState: PropTypes.bool,
  title: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  mainMenuState: mainMenuStateSelector(state, props),
});

export default connect(mapStateToProps)(Base);
