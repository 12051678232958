import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { isUserAuthorizedSelector } from 'selectors/authSelector';


const UserNonAuthWrapper = ({ children }) => {
  let history = useHistory();
  const isUserAuthorized = useSelector(isUserAuthorizedSelector)
  useEffect(() => {
    if (isUserAuthorized) {
      history.push("/");
    }
  }, [isUserAuthorized]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {children}
    </>
  );
}


UserNonAuthWrapper.defaultProps = {
};

UserNonAuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
}

export default UserNonAuthWrapper;
