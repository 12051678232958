import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Form, InputGroup } from 'react-bootstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import Filed from 'components/Fields';
import { getConfig } from 'common/Util';


const PasswordConfirm = ({ password1, password2, onPasswordChange, onConfirmPasswordChange}) => {
  const intl = useIntl();
  const passwordMatched = password1 === password2 && password1 !== '';
  const minLength = getConfig('passwordMinLength', 6);
  const shortScoreWord = intl.formatMessage({id:'lbl.password.short'});
  const passStrengthMessages = [
    intl.formatMessage({id:'lbl.password.score1'}),
    intl.formatMessage({id:'lbl.password.score2'}),
    intl.formatMessage({id:'lbl.password.score3'}),
    intl.formatMessage({id:'lbl.password.score4'}),
    intl.formatMessage({id:'lbl.password.score5'}),
  ];
  const pass2checkClass = classNames('bi', {
    'bi-x-circle': !passwordMatched,
    'text-danger': !passwordMatched,
    'bi-check-circle': passwordMatched,
    'text-success': passwordMatched,
  });
  return (
    <>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4" className="text-end">
          <FormattedMessage id="field.new_password.password1" />
        </Form.Label>
        <Col sm="6">
          <Filed
            fieldType="password"
            value={password1}
            onChange={onPasswordChange}
            placeholder={intl.formatMessage({id:'placeholder.new_password.password'})}
          />
          { password1 !== '' && (
            <PasswordStrengthBar
              password={password1}
              scoreWords={passStrengthMessages}
              shortScoreWord={shortScoreWord}
              minLength={minLength}
            />
          )}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4" className="text-end">
          <FormattedMessage id="field.new_password.password2" />
        </Form.Label>
        <Col sm="6">
          <InputGroup>
            <Filed
              fieldType="password"
              value={password2}
              onChange={onConfirmPasswordChange}
              placeholder={intl.formatMessage({id:'placeholder.new_password.reenter_password'})}
            />
            {password2 !== '' && (
              <InputGroup.Text>
                <i className={pass2checkClass} />
              </InputGroup.Text>
            )}
          </InputGroup>
        </Col>
      </Form.Group>
    </>
  );
}


PasswordConfirm.defaultProps = {
  password1: '',
  password2: '',
};

PasswordConfirm.propTypes = {
  password1: PropTypes.string,
  password2: PropTypes.string,
  onPasswordChange: PropTypes.func.isRequired,
  onConfirmPasswordChange: PropTypes.func.isRequired,
};

export default PasswordConfirm;
