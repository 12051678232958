import React from 'react';
import classNames from 'classnames';
import Date from './Date';
import DateTime from './DateTime';
import Price from './Price';
import Usage from './Usage';
import UsageType from './UsageType';
import Number from './Number';
import OfOn from './OfOn';

const DisplayValue = ({ type, className, ...otherProps }) => {
  const renderValue = () => {
    switch (type) {
      case 'date':
        return (<Date {...otherProps} />);
      case 'datetime':
        return (<DateTime {...otherProps} />);
      case 'price':
        return (<Price {...otherProps} />);
      case 'usage':
        return (<Usage {...otherProps} />);
      case 'usageType':
        return (<UsageType {...otherProps} />);
      case 'ofOn':
        return (<OfOn {...otherProps} />);
      case 'number':
        return (<Number {...otherProps} />);
      default:
          return otherProps.value;
    }
  }

  return (
    <span className={classNames('value', `value-${type}`, className)}>
      { renderValue() }
    </span>
  );
}

export default DisplayValue;