import { getConfig } from 'common/Util';

export const getSettingsQuery = (categories) => ({
  api: 'settings',
  params: [
    { categories: JSON.stringify(categories) },
  ],
});


export const getDownloadInvoiceQuery = (invoiceId, billrunKey) => ({
  api: 'account/downloadInvoice',
  params: [
    { query: JSON.stringify({
      invoice_id: invoiceId,
      billrun_key: billrunKey,
    }) },
  ],
});

export const getSubscriberBySidQuery = (sid) => ({
  api: 'subscriber',
  params: [
    { query: JSON.stringify({sid}) },
  ],
});

export const getSubscriberUsageBySidQuery = (query, sort, size = null, page = null) => {
  const params = [
    { query: JSON.stringify(query) },
    { sort: JSON.stringify(sort) },
  ];
  if (page !== null && size !== null) {
    params.push({ page });
    params.push({ size });
  }
  if (page === null && size !== null) {
    params.push({ page: 1 });
    params.push({ size });
  }
  return ({
    api: 'subscriber/usages',
    params
  });
}

export const getAccountQuery = () => ({
  api: 'account',
});

export const getAccountDebtQuery = () => ({
  api: 'account/debt',
  params: [
    { query: JSON.stringify({
      'only_debt': 0
    })},
  ],
});

export const getAccountOutstandingQuery = () => ({
  api: 'account/outstanding',
});

export const getAccountInvoicesQuery = () => ({
  api: 'account/invoices',
});

export const getAccountChargesQuery = (query)  => ({
  api: 'account/charges',
  params: [
    { query: JSON.stringify({
      ...query.toJS(),
      'type': 'ALL_CHARGES'
    })},
  ],
});

export const getAccountStatementsQuery = (query, sort)  => ({
  api: 'account/charges',
  params: [{
    query: JSON.stringify({
        ...query.toJS(),
        'type': 'SUCCESSFUL_CHARGES_AND_INVOICES'
      }),
    }, {
    sort: JSON.stringify(sort)
  }],
});

export const appLoginQuery = (token) => {
  const formData = new FormData();
  const authClientSecret = getConfig(['env', 'authClientSecret'], '');
  const authClientId = getConfig(['env', 'authClientId'], '');
  formData.append('grant_type', 'client_credentials');
  formData.append('client_id', authClientId);
  formData.append('client_secret', authClientSecret);
  formData.append('scope', 'selfcare account');
  return {
    api: 'oauth2',
    options: {
      method: 'POST',
      body: formData,
    },
  };
}

export const userLoginQuery = (username, password) => {
  const formData = new FormData();
  const authClientSecret = getConfig(['env', 'authClientSecret'], '');
  const authClientId = getConfig(['env', 'authClientId'], '');
  formData.append('grant_type', 'password');
  formData.append('client_id', authClientId);
  formData.append('client_secret', authClientSecret);
  formData.append('scope', 'selfcare account');
  formData.append('password', password);
  formData.append('username', username);
  return {
    api: 'oauth2',
    options: {
      method: 'POST',
      body: formData,
    },
  };
}

export const userSignUpQuery = (username, password, token) => ({
  api: 'registration/signUp',
  options: {
    method: 'POST',
    body: JSON.stringify({ password, username, token }),
  },
});

export const requestResetPasswordQuery = (username) => ({
  api: 'registration/sendResetPasswordEmail',
  options: {
    method: 'POST',
    body: JSON.stringify({ username }),
  },
});

export const resetPasswordQuery = (username, password, token) => ({
  api: 'registration/forgotPassword',
  options: {
    method: 'POST',
    body: JSON.stringify({ password, username, token }),
  },
});

export const updateAccountQuery = (oldPassword, newPassword) => ({
  api: 'account/updatePassword',
  options: {
    method: 'POST',
    body: JSON.stringify({ update: {
      old_password: oldPassword, 
      new_password: newPassword, 
    }}),
  },
});