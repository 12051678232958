import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';


const AppAuthError = (props) => (
  <div>
    {props.children && props.children}
    {!props.children && (
      <FormattedMessage id="error.app_login" />
    )}
  </div>
);

AppAuthError.defaultProps = {
  children: null,
};

AppAuthError.propTypes = {
  children: PropTypes.element,
};


export default AppAuthError;
