import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl';
import Immutable from 'immutable';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { isUserAuthorizedSelector } from 'selectors/authSelector';


const tooltipDelayConf = { show: 300, hide: 300 };

const MainMenuItem = ({ data, mainMenuState }) => {
  const isUserAuthorized = useSelector(isUserAuthorizedSelector);
  if (data.isEmpty()) {
    return null;
  }
  if (data.get('auth', false) && !isUserAuthorized) {
    return null;
  }
  const iconClass = classNames('bi', data.get('icon', ''));
  const labelClass = classNames({
    'd-none': mainMenuState,
    'd-none d-sm-none d-md-inline-block': !mainMenuState,
  });
  const tooltip = (
    <Tooltip id={data.get('title', '')}>
      <FormattedMessage id={data.get('title', '')} />
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="auto"
      delay={tooltipDelayConf}
      overlay={tooltip}
    >
      <NavLink exact to={data.get('path', '')}>
        <i className={iconClass} />
        <span className={labelClass}>
          <FormattedMessage id={data.get('title', '')} />
        </span> 
      </NavLink>
    </OverlayTrigger>
    )
};

MainMenuItem.defaultProps = {
  data: Immutable.Map(),
  mainMenuState: true,
};

MainMenuItem.propTypes = {
  data: PropTypes.instanceOf(Immutable.Map),
  mainMenuState: PropTypes.bool,
};

export default MainMenuItem;