
import {
  isAppAuthorizedSelector,
} from 'selectors/authSelector';
import {
  getSettingsQuery,
} from 'common/ApiQueries';
import {
  apiBillRunApp,
  // apiBillRunSuccessHandler,
  apiBillRunErrorHandler,
} from 'common/Api';

export const actions = {
  UPDATE_SETTING: 'UPDATE_SETTING',
  PUSH_TO_SETTING: 'PUSH_TO_SETTING',
  GOT_SETTINGS: 'GOT_SETTINGS',
  REMOVE_SETTING_FIELD: 'REMOVE_SETTING_FIELD',
};


export const updateSetting = (category, value, path = null) => ({
  type: actions.UPDATE_SETTING,
  category,
  value,
  path,
});

export const pushToSetting = (category, value, path = null) => ({
  type: actions.PUSH_TO_SETTING,
  category,
  path,
  value,
});

export const removeSettingField = (category, path) => ({
  type: actions.REMOVE_SETTING_FIELD,
  category,
  path,
});

export const gotSettings = data => ({
  type: actions.GOT_SETTINGS,
  data,
});

export const clearAppStorage = (keys = null) => {
  if (keys === null) {
    localStorage.clear();
  } else {
    const keysArray = Array.isArray(keys) ? keys : [keys];
    keysArray.forEach((key) => {
      localStorage.removeItem(key);
    });
  }
}

export const getSettings = (categories) => (dispatch, getState) => {
  const state = getState();
  if (!isAppAuthorizedSelector(state)) {
    return Promise.reject({ message: 'app_unauthorized' });
  }
  const query = getSettingsQuery(categories);
  return dispatch(apiBillRunApp(query))
    .then((success)=> {
      return dispatch(gotSettings(success.data));
    })
    .catch((error) => {
      return dispatch(apiBillRunErrorHandler(error));
    })
}
