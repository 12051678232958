import Immutable from 'immutable';
import { actions } from 'actions/subscriberActions';
import { actions as authActions } from 'actions/authActions';


const defaultState = Immutable.Map();

const subscriberReducer = (state = defaultState, action) => {
  switch (action.type) {

    case actions.SET_SUBSCRIBER_SELECTED: {
      const { sid } = action;
      return state.set('selected', sid);
    }
    case actions.CLEAR_SUBSCRIBER_SELECTED: {
      return state.delete('selected');
    }

    case actions.GOT_SUBSCRIBER_DETAILS: {
      const { data, sid } = action;
      const details = Immutable.fromJS(data.details);
      return state.setIn([sid, 'details'], details);
    }

    case actions.GOT_SUBSCRIBER_USAGES: {
      const { data, sid } = action;
      const usage = Immutable.fromJS(data.details);
      return state.setIn([sid, 'usage'], usage);
    }

    case actions.CLEAR_SUBSCRIBER_DETAILS: {
      return defaultState;
    }

    case authActions.USER_LOGOUT: {
      return defaultState;
    }
    default:
      return state;
  }
}


export default subscriberReducer;