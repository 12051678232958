import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import Filed from 'components/Fields';
import {
  requestResetPassword,
} from 'actions/authActions';


const RequestNew = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  let history = useHistory();
  const [userName, setUsername] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [captchaCode, setCaptchaCode] = useState('');
  const [isInvalidCode, setIsInvalidCode] = useState(false);

  const onChangeCaptchaCode = (code) => {
    setVerificationCode(code);
    setIsInvalidCode(false);
  }

  const onResetCaptchaCode = (code) => {
    setCaptchaCode(code);
    setIsInvalidCode(false);
  }

  const isValidResetRequest = () => {
    if (userName === '') {
      return false;
    }
    if (captchaCode !== verificationCode) {
      setIsInvalidCode(true);
      return false;
    }
    return true;
  }

  const onSubmitSendReset = (e) => {
    e.preventDefault();
    if (isValidResetRequest()) {
      dispatch(requestResetPassword(userName))
        .then((s) => { history.push({ pathname: '/reset-password', search: "?status=request_sent" });})
        .catch((e) => { history.push({ pathname: '/reset-password', search: "?status=request_sent" }); });
        // .catch((e) => { history.push({ pathname: '/reset-password', search: "?status=request_error" }); });
    }
  }
  const allowSubmitSendReset = userName !== '' && verificationCode !== '';
  return (
    <Container>
      <Row>
        <Col>
          <Form noValidate validated={false}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4" className="text-end">
                <FormattedMessage id="field.login_form.user_name" />
              </Form.Label>
              <Col sm="6">
                <Filed
                  value={userName}
                  onChange={setUsername}
                  placeholder={intl.formatMessage({id:'placeholder.login_form.user_name'})}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4" className="text-end">
                <FormattedMessage id="field.captcha" />
              </Form.Label>
              <Col sm="6">
                <Filed
                  fieldType="captcha"
                  value={verificationCode}
                  onChange={onChangeCaptchaCode}
                  onChangeCode={onResetCaptchaCode}
                  hasError={isInvalidCode}
                />
              </Col>
            </Form.Group>
            <Col sm="12" className="text-center">
              <Button type="submit" variant="success" onClick={onSubmitSendReset} className="text-end" disabled={!allowSubmitSendReset}>
                <FormattedMessage id="btn.send" />
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  ); 
}

export default RequestNew;
