import PropTypes from 'prop-types';
import { Provider } from 'react-redux'
import store from 'configureStore'
import { IntlProvider } from 'react-intl';


/**
 * To use this component need to wrap output with ReactDOMServer.renderToStaticMarkup()
 * and then strip html tags, for Example:
 *            const element = ReactDOMServer.renderToStaticMarkup(
 *              <ElementToText key={field} intl={intl}>
 *                <REACT ELEMENT TO EXTRACT TEXT />
 *              </ElementToText>
 *            );
 *            const text = element.replace(/<[^>]+>/g, '');
 */

 const ElementToText = ({ children, intl }) => {
  return (
    <Provider store={store}>
      <IntlProvider
        messages={intl.messages}
        locale={intl.locale}
        defaultLocale={intl.defaultLocale}
        timeZone={intl.timeZone}
      >
        {children && children}  
      </IntlProvider>
    </Provider>
  );
}


ElementToText.defaultProps = {
  children: null,
};

ElementToText.propTypes = {
  children: PropTypes.element,
  intl: PropTypes.object.isRequired,
};


export default ElementToText;
