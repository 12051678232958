import Immutable from 'immutable';
import { actions } from 'actions/settingsActions';
import { actions as authActions } from 'actions/authActions';


const defaultState = Immutable.fromJS({});

const settingsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.UPDATE_SETTING: {
      const { category, path, value } = action;
      let settingsPath;
      if (!path) {
        settingsPath = [category];
      } else if (Array.isArray(path)) {
        settingsPath = [category, ...path];
      } else {
        settingsPath = [category, path];
      }
      const data = Immutable.fromJS(value);
      return state.setIn(settingsPath, data);
    }

    case actions.PUSH_TO_SETTING: {
      const { category, path, value } = action;
      let settingsPath;
      if (!path) {
        settingsPath = [category];
      } else if (Array.isArray(path)) {
        settingsPath = [category, ...path];
      } else {
        settingsPath = [category, path];
      }
      return state.updateIn(settingsPath, Immutable.List(), list => list.push(value));
    }

    case actions.GOT_SETTINGS: {
      const { data } = action;
      const details = data?.details || {};
      const settings = Immutable.fromJS(details);
      return state.withMutations((stateWithMutations) => {
        settings.forEach((setting, path) => {
          stateWithMutations.setIn(path.split('.'), setting);
        });
      });
    }

    case actions.REMOVE_SETTING_FIELD: {
      const { category, path } = action;
      let settingsPath;
      if (!path) {
        settingsPath = [category];
      } else if (Array.isArray(path)) {
        settingsPath = [category, ...path];
      } else {
        settingsPath = [category, path];
      }
      return state.deleteIn(settingsPath);
    }

    case authActions.APP_LOGOUT: 
      return defaultState;

    default:
      return state;
  }
}


export default settingsReducer;