import Immutable from 'immutable';

import {
  PROGRESS_BAR_START,
  PROGRESS_BAR_FINISH,
  PROGRESS_LOADING_START,
  PROGRESS_LOADING_FINISH,
  PROGRESS_INDICATOR_DISMISS,
} from 'actions/progressIndicatorActions';

const defaultState = Immutable.Map({
  progresses: 0,
  isFullScreen: false,
});

const progressIndicatorReducer = (state = defaultState, action) => {
  switch(action.type) {
    case PROGRESS_BAR_START: {
      const isFull = state.get('isFullScreen', false);
      const newProgressesCount = isFull ? 1 : state.get('progresses', 0) + 1;
      return state.set('progresses', newProgressesCount).set('isFullScreen', false);
    }
    case PROGRESS_BAR_FINISH: {
      const progresses = state.get('progresses', 0);
      const newProgressesCount = (progresses > 0) ? progresses - 1 : 0;
      return state.set('progresses', newProgressesCount);
    }
      case PROGRESS_LOADING_START:
        return state.set('isFullScreen', true).set('progresses', 1);
      case PROGRESS_LOADING_FINISH:
      case PROGRESS_INDICATOR_DISMISS:
        return state.set('isFullScreen', false).set('progresses', 0);
    default:
      return state;
  }
}

export default progressIndicatorReducer;