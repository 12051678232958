// import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmModal } from 'components/Elements';
import { hideConfirmModal } from 'actions/modalActions';
import { confirmSelector } from 'selectors/modalSelectors';


const ReduxConfirmModal = () => {
  const dispatch = useDispatch();
  const confirm = useSelector(confirmSelector);

  if (confirm.get('show', false) === false) {
    return null;
  }

  const hideConfirm = callback => () => {
    if (callback && typeof callback === 'function') {
      callback();
    }
    dispatch(hideConfirmModal());
  };

  const labelOk = confirm.get('labelOk');
  const onOk = hideConfirm(confirm.get('onOk'));
  const labelCancel = confirm.get('labelCancel');
  const onCancel = hideConfirm(confirm.get('onCancel'));
  const message = confirm.get('message');
  const type = confirm.get('type');
  const children = confirm.get('children');
  return (
    <ConfirmModal
      show={true}
      labelOk={labelOk}
      onOk={onOk}
      labelCancel={labelCancel}
      onCancel={onCancel}
      message={message}
      type={type}
    >
      {children}
    </ConfirmModal>
  );
};

export default ReduxConfirmModal;
