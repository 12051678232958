import React from 'react';
import Immutable from 'immutable';
import classNames from 'classnames';
import { Actions } from 'components/Elements';
import ColumnValue from './ColumnValue';



const Row = ({ data, fields, entity, rowActions = Immutable.List() }) => (
  <tr>
    {fields.map((field, idx) => {
      const style = classNames({
        [field.get('type', '')]: field.has('type',),
      });
      return (
        <td key={idx} className={style}>
          <ColumnValue data={data} config={field}/>
        </td>
      )}
      )}
      { rowActions.size > 0 && (
        <td className="actions">
          <Actions actions={rowActions} data={data} />
        </td>
      )}
  </tr>
);

export default Row;