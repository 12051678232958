import React, { Suspense, lazy } from 'react';
import { injectIntl } from 'react-intl';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Loading } from 'components/Elements';
import Base from 'components/Layout/Base'
import { UserAuthWrapper, UserNonAuthWrapper } from 'components/Auth';


const Home = lazy(() => import('components/Pages/Home'));
const Page404 = lazy(() => import('components/Pages/Page404'));
const Login = lazy(() => import('components/Pages/Login'));
const MyAccount = lazy(() => import('components/Pages/MyAccount'));
const Invoices = lazy(() => import('components/Pages/Invoices'));
const Usage = lazy(() => import('components/Pages/Usage'));
const Charges = lazy(() => import('components/Pages/Charges'));
const Statement = lazy(() => import('components/Pages/Statement'));
const LanguageFile = lazy(() => import('components/Pages/LanguageFile'));
const Signup = lazy(() => import('components/Pages/Signup'));
const ResetPassword = lazy(() => import('components/Pages/ResetPassword'));


const Routes = ({ intl }) => (
  <Router>
    <Switch>
      <Route path="/login" >
        <Base title={intl.formatMessage({id:'page.title.login'})}>
          <UserNonAuthWrapper>
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          </UserNonAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/my-account">
        <Base title={intl.formatMessage({id:'page.title.myAccount'})}>
          <UserAuthWrapper>
            <Suspense fallback={<Loading />}>
              <MyAccount />
            </Suspense>
          </UserAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/invoices">
        <Base title={intl.formatMessage({id:'page.title.invoices'})}>
          <UserAuthWrapper>
            <Suspense fallback={<Loading />}>
              <Invoices />
            </Suspense>
          </UserAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/usage">
        <Base title={intl.formatMessage({id:'page.title.usage'})}>
          <UserAuthWrapper>
            <Suspense fallback={<Loading />}>
              <Usage />
            </Suspense>
          </UserAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/charges">
        <Base title={intl.formatMessage({id:'page.title.charges'})}>
          <UserAuthWrapper>
            <Suspense fallback={<Loading />}>
              <Charges />
            </Suspense>
          </UserAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/statement">
        <Base title={intl.formatMessage({id:'page.title.statement'})}>
          <UserAuthWrapper>
            <Suspense fallback={<Loading />}>
              <Statement />
            </Suspense>
          </UserAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/signup">
        <Base title={intl.formatMessage({id:'page.title.signup'})}>
          <UserNonAuthWrapper>
            <Suspense fallback={<Loading />}>
              <Signup />
            </Suspense>
          </UserNonAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/reset-password">
        <Base title={intl.formatMessage({id:'page.title.reset_password'})}>
          <UserNonAuthWrapper>
            <Suspense fallback={<Loading />}>
              <ResetPassword />
            </Suspense>
          </UserNonAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/lang-file">
        <Base title="Language File Downloader">
          <UserAuthWrapper>
            <Suspense fallback={<Loading />}>
              <LanguageFile />
            </Suspense>
          </UserAuthWrapper>
        </Base>
      </Route>
      <Route exact path="/">
        <Base title={intl.formatMessage({id:'page.title.home'})}>
          <UserAuthWrapper>
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          </UserAuthWrapper>
        </Base>
      </Route>
      <Route path="*">
        <Base>
          <Suspense fallback={<Loading />}>
            <Page404 />
          </Suspense>
        </Base>
      </Route>
    </Switch>
  </Router>
);


export default injectIntl(Routes);