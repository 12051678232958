const fr = {
  version: "version de l'application",
  page: {
    title: {
      about: "Sur",
      home: "Domicile",
    }
  },
  lbl : {
    loading:"Chargement...",
  },
};

export default fr;