import {
  apiBillRunUser,
  apiBillRunErrorHandler,
} from 'common/Api';
import {
  getSubscriberBySidQuery,
  getSubscriberUsageBySidQuery,
} from 'common/ApiQueries';
import { setTotalPages } from 'actions/listActions';
import { isSubscriberDetailsExistsSelector } from 'selectors/subscriberSelector';
import {
  getListFilterValuesSelector,
  getListSizeSelector,
  getListCurrentPageSelector,
  getListSortValuesSelector,
} from 'selectors/listSelectors';
import { listFilterBuilder } from 'common/Util';
import Immutable from 'immutable';


export const actions = {
  GOT_SUBSCRIBER_DETAILS: 'GOT_SUBSCRIBER_DETAILS',
  CLEAR_SUBSCRIBER_DETAILS: 'CLEAR_SUBSCRIBER_DETAILS',
  GOT_SUBSCRIBER_USAGES: 'GOT_SUBSCRIBER_USAGES',
  SET_SUBSCRIBER_SELECTED: 'SET_SUBSCRIBER_SELECTED',
  CLEAR_SUBSCRIBER_SELECTED: 'CLEAR_SUBSCRIBER_SELECTED',
};


export const clearSubscriberSelected = () => ({
  type: actions.CLEAR_SUBSCRIBER_SELECTED,
});

export const setSubscriberSelected = (sid) => ({
  type: actions.SET_SUBSCRIBER_SELECTED,
  sid,
});

const gotSubscriberDetails = (sid, data) => ({
  type: actions.GOT_SUBSCRIBER_DETAILS,
  sid,
  data,
});

export const clearSubscriberDetails = () => ({
  type: actions.CLEAR_SUBSCRIBER_DETAILS,
});

export const gotSubscriberUsage = (sid, data) => ({
  type: actions.GOT_SUBSCRIBER_USAGES,
  sid,
  data,
});


export const getSubscriberUsage = (sid) => (dispatch, getState) => {
  if (sid === '') {
    return Promise.reject({ message: 'missing_required_fields', fields: 'subscriber' });
  }
  const store = getState();
  const filters = getListFilterValuesSelector(store, 'usage');
  const requestQuery = listFilterBuilder(filters, store)
    .set('included_in_plan', Immutable.Map({"$exists":0}))
    .set('sid', sid);
  const size = getListSizeSelector(store, 'usage');
  const page = getListCurrentPageSelector(store, 'usage');
  const sorts = getListSortValuesSelector(store, 'usage');
  const query = getSubscriberUsageBySidQuery(requestQuery, sorts, size, page);
  return dispatch(apiBillRunUser(query))
    .then((success)=> {
      dispatch(setTotalPages('usage', success.data));
      return dispatch(gotSubscriberUsage(sid, success.data));
    })
    .catch((error) => dispatch(apiBillRunErrorHandler(error)) )
}

export const getExportSubscriberUsage = (sid) => (dispatch, getState) => {
  if (sid === '') {
    return Promise.resolve([]);
  }
  const store = getState();
  const filters = getListFilterValuesSelector(store, 'usage');
  const requestQuery = listFilterBuilder(filters, store)
    .set('sid', sid);
  const sorts = getListSortValuesSelector(store, 'usage');
  const query = getSubscriberUsageBySidQuery(requestQuery, sorts);
  return dispatch(apiBillRunUser(query))
    .then((success)=> success.data.details )
    .catch((error) => {
      dispatch(apiBillRunErrorHandler(error));
      Promise.resolve([]);
    })
}

export const getSubscriberDetails = (sid) => (dispatch, getState) => {
  if (isSubscriberDetailsExistsSelector(getState())) {
    return Promise.resolve();
  }
  const query = getSubscriberBySidQuery(sid);
  return dispatch(apiBillRunUser(query))
    .then((success)=> dispatch(gotSubscriberDetails(sid, success.data)))
    .catch((error) => dispatch(apiBillRunErrorHandler(error)))
}