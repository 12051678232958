import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { appDoLogin } from 'actions/authActions';
import { useDispatch, useSelector } from 'react-redux'
import {
  isAppAuthorizedSelector,
  isAppAuthorizationErrorSelector,
} from 'selectors/authSelector';
import { Loading, AppAuthError } from 'components/Elements';
import Content from 'components/Layout/Content';


const AppAuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const isAppAuthorized = useSelector(isAppAuthorizedSelector)
  const isAppAuthorizationError = useSelector(isAppAuthorizationErrorSelector)

  useEffect(() => {
    if (!isAppAuthorized) {
      dispatch(appDoLogin());
    }
  }, [isAppAuthorized]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isAppAuthorizationError) {
    return (
      <Content>
        <AppAuthError />
      </Content>
    );
  }
  if (!isAppAuthorized) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }
  return (
    <>
      {children}
    </>
  );
}


AppAuthWrapper.defaultProps = {
};


AppAuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
}


export default AppAuthWrapper;
