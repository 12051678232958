export const PROGRESS_BAR_START = 'PROGRESS_BAR_START';
export const PROGRESS_BAR_FINISH = 'PROGRESS_BAR_FINISH';
export const PROGRESS_LOADING_START = 'PROGRESS_LOADING_START';
export const PROGRESS_LOADING_FINISH = 'PROGRESS_LOADING_FINISH';
export const PROGRESS_INDICATOR_DISMISS = 'PROGRESS_INDICATOR_DISMISS';

export const startProgressBar = () => ({
  type: PROGRESS_BAR_START,
});

export const finishProgressBar = () => ({
  type: PROGRESS_BAR_FINISH,
});

export const dismissProgressIndicator = () => ({
  type: PROGRESS_INDICATOR_DISMISS,
});

export const startProgressLoading = () => ({
  type: PROGRESS_LOADING_START,
});

export const finishProgressLoading = () => ({
  type: PROGRESS_LOADING_FINISH,
});
