import Immutable from 'immutable';
import moment from 'moment';
import isNumber from 'is-number';
import systemConfig from '../config/system';
import { invoicingDaySelector } from 'selectors/settingsSelectors';


let configCache = Immutable.Map();

export const getConfig = (key, defaultValue = null) => {
  const path = Array.isArray(key) ? key : [key];
  if (configCache.isEmpty()) {
    configCache = Immutable.fromJS(systemConfig);
  }

  if (!configCache.has(path[0])) {
    switch (path[0]) {
      case 'env': {
        const env = Immutable.Map().withMutations((envWithMutations) => {
          Object.entries(process.env).forEach((value) => {
            if (value[0].startsWith('REACT_APP_')) {
              const envKey = value[0].replace("REACT_APP_", "");
              let envValue = value[1];
              if (value[1] === 'true') {
                envValue = true;
              } else if(value[1] === 'false') {
                envValue = false;
              } else if (isNumber(envValue)) {
                envValue = parseFloat(envValue);
              }
              envWithMutations.set(envKey, envValue);
            }
          });
        });
        configCache = configCache.set('env', env);
      }
        break;
      default: console.log(`Config category not exists ${path}`);
    }
  }
  return configCache.getIn(path, defaultValue);
};

export const getDefaultLanguage = (config) => {
  return config.filter(langConf => langConf.get('isDefault', false)).keySeq().first('en');
}

export const getEnabledLanguages = (config) => {
  return config.filter(langConf => langConf.get('isActive', true));
}

export const getLanguageDir = (lang, config) => {
  return config.getIn([lang, 'dir'], 'ltr');
}

export const getLanguageCode = (lang, config) => {
  return config.getIn([lang, 'code'], lang);
}

export const getUsageColor = (val) => {
  if (val < 60) {
    return '#198754'; // green
  }
  if (val >= 80) {
    return '#dc3545'; // red
  }
  return '#ffc107'; // yellow
}

export const getStringPlaceholders = (str) => Immutable.List(
  str.split('{{')
  .filter(val => val.includes('}}'))
  .map(val => val.substring(0, val.indexOf('}}')))
);

const getEntityFieldValueReplacements = (config, entity) => {
  if (!config) {
    return Immutable.Map();
  }
  if (config.hasIn(['display_config', 'map', 'field'])) {
    const fieldName = config.getIn(['display_config', 'map', 'field'], '');
    return Immutable.Map({[fieldName]: entity.get(fieldName, '')});
  }
  if (config.hasIn(['display_config', 'map', 'fields'])) {
    return config.getIn(['display_config', 'map', 'fields'], Immutable.List()).reduce((acc, fieldName) => {
      if (entity.get(fieldName, '') !== '') {
        return acc.set(fieldName, entity.get(fieldName, ''));
      }
      return acc;
    }, Immutable.Map());
  }
  const fieldName = config.get('field_name', '');
  return Immutable.Map({[fieldName]: entity.get(fieldName, '')});
}

export const formatEntityValue = (config, values) => {
  if (values.every(val => val === '')) {
    return '';
  }
  if (config.hasIn(['display_config', 'map', 'template'])) {
    const template = config.getIn(['display_config', 'map', 'template'], '');
    const placeholders = getStringPlaceholders(template);
    return placeholders.reduce((acc, placeholder) => {
      let value = values.get(placeholder, '');
      if (config.hasIn(['display_config', 'map', 'trimZero'])) {
        value = value.replace(/^0+/, '');
      }
      return acc.replaceAll(`{{${placeholder}}}`, value);
    }, template);
  }
  return values.toList().join(' ');
}

export const getEntityFieldValue = (config, entity) => {
  const values = getEntityFieldValueReplacements(config, entity);
  return formatEntityValue(config, values);
}

export const getEntityConfigFieldByName = (configFields, fieldName) => 
  configFields.filter(configField => configField.get('field_name', '') === fieldName).first(Immutable.Map());

export const createSubscriberSelectLabel = (subscriber, config) => {
  const display_name = config.get('display_name', '{{full_name}}');
  const placeholders = getStringPlaceholders(display_name);
  const configFields = config.get('fields', Immutable.List());
  return placeholders.reduce((acc, placeholder) => {
    let config = getEntityConfigFieldByName(configFields, placeholder);
    let value = getEntityFieldValue(config, subscriber);
    return acc.replaceAll(`{{${placeholder}}}`, value);
  }, display_name);
}

export const getBillingCycles = () => {
  const cyclesToDisplay = getConfig('billingCyclesToDisplayInSelect', 12);
  let cycles = [];
  const cycle = moment();
  for (let index = 0; index < cyclesToDisplay; index++) {
    cycles.push(cycle.format('YYYYMM'));
    cycle.subtract(1, 'month');
  }
  return cycles;
}

export const getUsageTypesOptions = (types, intl) => () => {
  return types.map(type => Immutable.Map({
    value: type.get('usage_type', ''),
    label: intl.formatMessage({id:`usage_type.${type.get('usage_type', '')}`, defaultMessage: `${type.get('label', '')}*`})
  }))
  .push(Immutable.Map({
    value: 'flat',
    label: intl.formatMessage({id:`usage_type.flat`})
  }))
  .push(Immutable.Map({
    value: 'discount',
    label: intl.formatMessage({id:`usage_type.discount`})
  }))
  .push(Immutable.Map({
    value: 'credit',
    label: intl.formatMessage({id:`usage_type.credit`})
  }))
  .toJS();
}

export const getBillingCyclesOptions = (invoicingDay) => () => {
  const cycles = getBillingCycles();
  return cycles.reduce((acc, cycle) => {
    return acc.push({value:cycle, label: parseCycle(invoicingDay, cycle)});
  }, Immutable.List()).toJS();
}

export const getInvoicingDate = (invoicingDay, next = true) => {
  const invoicingDate = moment().set('date', invoicingDay).startOf('day');
  if (next && moment().diff(invoicingDate) > 0) {
    return invoicingDate.add(1, 'month');
  }
  return invoicingDate;
}

export const cycleToRange = (invoicingDay, cycle) => {
  const date = moment(cycle, 'YYYYMM').set('date', invoicingDay).startOf('day');
  const to = date.format('YYYY-MM-DD');
  const from = date.subtract(1, 'month').format('YYYY-MM-DD');
  return { from, to };
}

export const parseCycle = (invoicingDay, value)  => {
  if (value) {
    const date = moment(value, 'YYYYMM').set("date",invoicingDay).startOf('day');
    const to = date.format('DD/MM/YY');
    const from = date.subtract(1, 'month').format('DD/MM/YY');
    return `${from} - ${to}`;
  }
  return value;
}

export const valueToDataUnit = (value) => {
  if (!isNaN(value) && value > 0) {
    let val = value / 1024 / 1024 / 1024; // GiB
    let unit = 'gigabyte';
    if (val < 1) {
      val *= 1024; // MiB
      unit = 'megabyte';
      return {val, unit};
    }
    return {val, unit};
  }
  return { val: 0, unit: '' };
}

export const listFilterBuilder = (filters, store) => filters
  .reduce((acc, value, field) => {
    switch (field) {
      case 'type':
        if (value && Array.isArray(value) && value.length > 0) {
          return acc.set("usaget", Immutable.Map({"$in": value}));
        } else {
          return acc;
        }
      case 'billrun': {
        if (value) {
          const invoicingDay = invoicingDaySelector(store);

          const { from, to } = cycleToRange(invoicingDay, value);
          return acc.set("urt", Immutable.Map({"$gte":from,"$lt":to}));
        } else {
          return acc;
        }
      }
      case 'final_charge': {
        if (value === 'charged') {
          return acc.set("final_charge", Immutable.Map({"$gt":0}));
        }
        if (value === 'uncharged') {
          return acc.set("final_charge", Immutable.Map({"$eq":0}));
        }
        return acc;
      }
      default:
        return acc;
    }
  }, Immutable.Map());

  export const getFileNameFormResponseHeader = (str, defaultName = 'file') => {
    if (str) {
      const fileNamePart =  str.split(';').find(n => n.includes('filename='));
      if (fileNamePart) {
        return fileNamePart
          .replace('filename=', '')
          .replaceAll('"', '')
          .trim();
      }
    }
    return defaultName;
  }

  export const isUnlimited = (value) => {
    if (["UNLIMITED", 'unlimited'].includes(value)) {
      return true;
    }
    const max = getConfig('unlimitedInt', '');
    if (isNumber(value) && max !== '') {
      return parseFloat(value) > max;
    }
    return false;
  }  

  export const getMessageByStatus = (status) => {
    switch (status) {
      case 'request_sent':
        return 'lbl.request_reset_password_success';
      case 'reset_error':
        return 'error.reset_password_fail';
      case 'request_error':
        return 'error.request_reset_password_fail';
      default:
        return 'error.general';
    }
  }

  export const passwordHint = (intl) => {
    const passLength = getConfig('passwordMinLength', 6);
    let requirements = [];
    const passUppercase = getConfig('passwordMustUppercase', false);
    if (passUppercase) {
      requirements.push(intl.formatMessage({ id: 'lbl.password.uppercase' }));
    }
    const passLowercase = getConfig('passwordMustLowercase', false);
    if (passLowercase) {
      requirements.push(intl.formatMessage({ id: 'lbl.password.lowercase' }));
    }
    const passNumber = getConfig('passwordMustNumber', false);
    if (passNumber) {
      requirements.push(intl.formatMessage({ id: 'lbl.password.number' }));
    }
    const passSymbols = getConfig('passwordMustSymbols', false);
    if (passSymbols) {
      requirements.push(intl.formatMessage({ id: 'lbl.password.symbols' }));
    }
    requirements = requirements.join(', ');
    return intl.formatMessage({ id: 'lbl.password.hint' }, { pass_len: passLength, requirements: requirements });
  }
