export const actions = {
  EDIT_FORM_SHOW: 'EDIT_FORM_SHOW',
  EDIT_FORM_HIDE: 'EDIT_FORM_HIDE',
  EDIT_FORM_SET_ITEM: 'EDIT_FORM_SET_ITEM',
  EDIT_FORM_SET_ERROR: 'EDIT_FORM_SET_ERROR',
  EDIT_FORM_UPDATE_ITEM_FIELD: 'EDIT_FORM_UPDATE_ITEM_FIELD',
  EDIT_FORM_DELETE_ITEM_FIELD: 'EDIT_FORM_DELETE_ITEM_FIELD',
  CONFIRM_SHOW: 'CONFIRM_SHOW',
  CONFIRM_HIDE: 'CONFIRM_HIDE',
};


export const showConfirmModal = config => ({
  type: actions.CONFIRM_SHOW,
  config,
});

export const hideConfirmModal = () => ({
  type: actions.CONFIRM_HIDE,
});

export const showFormModal = (item, component, config) => ({
  type: actions.EDIT_FORM_SHOW,
  item,
  component,
  config,
});

export const hideFormModal = () => ({
  type: actions.EDIT_FORM_HIDE,
});

export const setFormModalItem = item => ({
  type: actions.EDIT_FORM_SET_ITEM,
  item,
});

export const setFormModalError = (fieldId, message = null) => ({
  type: actions.EDIT_FORM_SET_ERROR,
  fieldId,
  message,
});

export const updateFormModalItemField = (path, value) => ({
  type: actions.EDIT_FORM_UPDATE_ITEM_FIELD,
  path,
  value,
});

export const removeFormModalItemField = path => ({
  type: actions.EDIT_FORM_DELETE_ITEM_FIELD,
  path,
});