import React from 'react';
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { ModalWrapper } from 'components/Elements';
import {
  formModalShowStateSelector,
  formModalItemSelector,
  formModalComponentSelector,
  formModalConfigSelector,
  formModalErrorsSelector,
} from 'selectors/modalSelectors';
import {
  hideFormModal,
  setFormModalItem,
  setFormModalError,
  updateFormModalItemField,
  removeFormModalItemField,
  showConfirmModal,
} from 'actions/modalActions';


const form = (WrappedComponent, {
  item, setItem, updateField, removeField, setError, ...otherProps
}) => (
  <WrappedComponent
    {...otherProps}
    item={item}
    setItem={setItem}
    setError={setError}
    updateField={updateField}
    removeField={removeField}
  />
);

const ReduxFormModal = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const show = useSelector(formModalShowStateSelector);
  const component = useSelector(formModalComponentSelector);
  const item = useSelector(formModalItemSelector);
  const config = useSelector(formModalConfigSelector);
  const errors = useSelector(formModalErrorsSelector);

  const closeModal = () => {
    const onOk = () => dispatch(hideFormModal());
    const confirm = {
      message: intl.formatMessage({id:'lbl.confirm_discard'}),
      labelOk: intl.formatMessage({id:'btn.yes'}),
      labelCancel: intl.formatMessage({id:'btn.no'}),
      type: 'delete',
      onOk,
    };
    return dispatch(showConfirmModal(confirm));
  }

  const hideModal = (callback) => (params) => {
    if (callback && typeof callback === 'function') {
      const result = callback(params);
      if (result && result.then && typeof result.then === 'function') { // if Promise
        return result
          .then(() => dispatch(hideFormModal()) )
          .catch(() => {});
      }
      if (result !== false) {
        return dispatch(hideFormModal());
      }
      return false;
    }
    return dispatch(hideFormModal());
  }

  const setItem = (newItem) => {
    dispatch(setFormModalItem(newItem));
  }

  const updateField = (path, value) => {
    dispatch(updateFormModalItemField(path, value));
  }

  const removeField = (path) => {
    dispatch(removeFormModalItemField(path));
  }

  const setError = (fieldId, message) => {
    dispatch(setFormModalError(fieldId, message));
  }


  if (!show) {
    return null;
  }

  if (show && !component) {
    throw new Error('ReduxFormModal require component parameter');
  }

  const {
    title, labelOk, onOk, labelCancel, onCancel, modalSize = 'lg',
    allowSubmitWithError = false, showOnOk = true, ...configOtherProps
  } = config.toJS();

  const labelOkForm = labelOk ? labelOk: intl.formatMessage({id:'btn.save'});


  const onOkWithHide = () => {
    if (!allowSubmitWithError) {
      const hasError = errors.some(error => !error || error.length > 0);
      if (hasError) {
        return false;
      }
    }
    const callback = hideModal(onOk);
    return callback(item);
  };

  const onCancelWithHide = hideModal(onCancel);

  return (
    <ModalWrapper
      show={true}
      title={title}
      onOk={onOkWithHide}
      labelOk={labelOkForm}
      onCancel={onCancelWithHide}
      labelCancel={labelCancel}
      onHide={closeModal}
      modalSize={modalSize}
      showOnOk={showOnOk}
    >
      { form(component, {
        item,
        errors,
        setItem,
        updateField,
        removeField,
        setError,
        ...configOtherProps,
      })}
    </ModalWrapper>
  );
};

export default ReduxFormModal;
