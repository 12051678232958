import { createSelector } from 'reselect';
import Immutable from 'immutable';
import { getConfig } from 'common/Util';


const getList = (state, props) => state.list;

const getListFilter = createSelector(
  getList,
  (list = Immutable.Map()) => list.get('filter')
);

const getListSort = createSelector(
  getList,
  (list = Immutable.Map()) => list.get('sort')
);

const getListSize = createSelector(
  getList,
  (list = Immutable.Map()) => list.get('size')
);

const getListTotal = createSelector(
  getList,
  (list = Immutable.Map()) => list.get('total')
);

const getListCurrentPage = createSelector(
  getList,
  (list = Immutable.Map()) => list.get('page')
);

export const getFilterValueSelector = createSelector(
  getListFilter,
  (state, entity, field) => entity,
  (state, entity, field) => field,
  (filters = Immutable.Map(), entity, field) => {
    return filters.getIn([entity, field], '');
  },
);

export const getListSizeSelector = createSelector(
  getListSize,
  (state, entity) => entity,
  (sizes = Immutable.Map(), entity) => {
    return sizes.get(entity, getConfig('defaultListSize', 20));
  },
);

export const getListCurrentPageSelector = createSelector(
  getListCurrentPage,
  (state, entity) => entity,
  (pages = Immutable.Map(), entity) => {
    return pages.get(entity, 1);
  },
);

export const getListTotalPagesSelector = createSelector(
  getListTotal,
  (state, entity) => entity,
  (totals = Immutable.Map(), entity) => {
    return totals.get(entity, 1);
  },
);

export const getListFilterValuesSelector = createSelector(
  getListFilter,
  (state, entity) => entity,
  (filters = Immutable.Map(), entity) => {
    return filters.getIn([entity], Immutable.Map());
  },
);

export const getListSortValueSelector = createSelector(
  getListSort,
  (state, entity) => entity,
  (state, entity, field) => field,
  (sorts = Immutable.Map(), entity, field) => {
    return sorts.getIn([entity, field]);
  },
);

export const getListSortValuesSelector = createSelector(
  getListSort,
  (state, entity) => entity,
  (sorts = Immutable.Map(), entity) => {
    return sorts
      .getIn([entity], Immutable.Map())
      .filter(sort => [1,-1].includes(sort));
  },
);
