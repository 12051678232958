import { FormattedMessage } from 'react-intl';
import { Col, Row, Container } from 'react-bootstrap';
import { getMessageByStatus } from 'common/Util';


const Result = ({ status = '' }) => {
  const messageId = getMessageByStatus(status);
  return (
    <Container>
      <Row>
        <Col>
          <p><FormattedMessage id={messageId} /></p>
        </Col>
      </Row>
    </Container>
  );
}

export default Result;

