import React from 'react';
import Immutable from 'immutable';
import moment from 'moment';
import isNumber from 'is-number';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { valueToDataUnit } from 'common/Util';


const Usage = ({ value, config = Immutable.Map() }) => {
  switch (config.getIn(['additionData', 'usaget'], '').toLocaleLowerCase()) {
    case 'call':
    case 'incoming_call': {
      if (config.getIn(['additionData', '_display_style'], '') === 'duration') {
        return moment
          .duration(value, "seconds")
          .format("hh:mm:ss", { trim: false });
      }
      return (
        <>
          {Math.round(value / 60)}
          {config.getIn(['additionData', 'show_units'], true) && (<> <FormattedMessage id={`lbl.minutes`}/></>)}
        </>
      );
    }
    case 'data': {
      if (isNumber(value) && value > 0) {
        const {val, unit} = valueToDataUnit(value);
        return (
          <FormattedNumber
            value={val.toFixed(2)}
            style="unit"
            unit={unit}
            unitDisplay="short"
          />
        );
      }
      return value;
    }
    case 'sms':
    default:
      return value;
  }
}

export default Usage;

