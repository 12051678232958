import { useContext, useEffect, useCallback, useState } from 'react';
import Immutable from 'immutable';
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import classNames from 'classnames';
import { Row, Col, Table, Accordion, Card, Button, useAccordionButton, AccordionContext } from 'react-bootstrap';
import { FormattedMessage} from 'react-intl';
import Header from './Header';
import ListRow from './Row';
import Pager from './Pager';
import Filter from './Filter';
import { Actions } from 'components/Elements';
import {
  getListFilterValuesSelector,
  getListCurrentPageSelector,
  getListSizeSelector,
  getListSortValuesSelector,
} from 'selectors/listSelectors';


const List = ({
  entity,
  fields = Immutable.List(),
  filters = Immutable.List(),
  data = Immutable.Map(),
  rowActions = Immutable.List(),
  listActions = null,
  reloadData = null,
  header = null,
  withPager = false,
}) => {
  const intl = useIntl();
  const filter = useSelector((state) => getListFilterValuesSelector(state, entity));
  const [filterState, setFilterState] = useState(filter.isEmpty());
  const listSize = useSelector((state) => getListSizeSelector(state, entity));
  const currentPage = useSelector((state) => getListCurrentPageSelector(state, entity));
  const currentSort = useSelector((state) => getListSortValuesSelector(state, entity));

  useEffect(() => {
    if (reloadData) {
      reloadData();
    }
  }, [currentPage, listSize, JSON.stringify(currentSort)]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickRefresh = useCallback(() => {
    if (reloadData) {
      reloadData();
    }
  }, [reloadData]);

  // const onClickNew = () => {
  //   console.log('onClickNew');
  // }

  const getListActions = () => {
    const defaultActions = Immutable.fromJS([{
      type: 'refresh',
      helpText: intl.formatMessage({id:'table.refresh'}),
      showIcon: true,
      onClick: onClickRefresh,
      actionStyle: 'primary',
      actionSize: 'sm',
    // }, {
    //   type: 'add',
    //   label: 'Add New',
    //   actionStyle: 'primary',
    //   showIcon: true,
    //   onClick: onClickNew,
    //   actionSize: 'sm',
    }]);
    if (listActions === null) {
      return defaultActions;
    }
    return listActions.map((listAction) => {
      const defaultAction = defaultActions.find(action => action.get('type', '') === listAction.get('type', ''));
      if (defaultAction) {
        return defaultAction.merge(listAction);
      }
      return listAction;
    }).reverse();
  }

  const onClickFilter = () => {
    if (reloadData) {
      reloadData();
    }
  }

  const allListActions = getListActions();

  const CustomToggle = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setFilterState(!filterState);
    });
    const btnClass =  classNames('bi', {
      'bi-chevron-up': activeEventKey !== null,
      'bi-chevron-down': activeEventKey === null,
    });
    
    return (
      <span className="accordion-toggle-btn">
        <Button onClick={decoratedOnClick} variant="link" disabled={!filter.isEmpty()}>
          <i className={btnClass} /> {" "}
          <>
            {children}
          </>
        </Button>
      </span>
    );
  }
  const isFilterOpen = !filter.isEmpty() || filterState ?  '0' : null;

  return (
    <div className="items-list">
      {(header !== null || !allListActions.isEmpty()) && (
        <Row className="mb-3">
          {!allListActions.isEmpty() && (
            <Col sm="8" className="text-start">
              {header}
            </Col>
          )}
          {!allListActions.isEmpty() && (
            <Col sm="4" className="text-end">
              <Actions actions={allListActions} />
            </Col>
          )}
        </Row>
      )}
      {!filters.isEmpty() && (
        <Accordion activeKey={isFilterOpen}>
          <Card>
            <Card.Header className="table-filter pt-0 pb-0 ps-0">
              <span className="float-start">
                <CustomToggle eventKey="0">
                  <FormattedMessage id={`table.filter`} />
                </CustomToggle>
              </span> 
              <span className="float-end">
                <Button
                  onClick={onClickFilter}
                  variant="primary"
                  size="sm"
                  className="mt-2 ps-1 pe-1 p-0"
                >
                  <i className="bi bi-funnel" />{" "}
                  <FormattedMessage id={`btn.filter`} />
                </Button>
              </span> 
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Filter filters={filters} entity={entity} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      )}

      <Table striped bordered hover>
        <thead>
          <Header fields={fields} hasActions={!rowActions.isEmpty()} entity={entity}/>
        </thead>
        <tbody>
          {data.map((rowData, idx) => (
            <ListRow key={`row_${idx}`} data={rowData} fields={fields} rowActions={rowActions} />
          )).toList() }
        </tbody>
      </Table>
      { withPager && (
        <Pager entity={entity} />
      )}
    </div>
  );
}

export default List;