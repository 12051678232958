import {
  apiBillRunUser,
  apiBillRunErrorHandler,
} from 'common/Api';
import {
  getAccountQuery,
  getAccountChargesQuery,
  getAccountInvoicesQuery,
  getAccountStatementsQuery,
  getDownloadInvoiceQuery,
  getAccountDebtQuery,
  getAccountOutstandingQuery,
  updateAccountQuery,
} from 'common/ApiQueries';
import {
  getListFilterValuesSelector,
  getListSortValuesSelector,
} from 'selectors/listSelectors';
import { showSuccess } from 'actions/alertsActions';
import { listFilterBuilder } from 'common/Util';


export const actions = {
  GOT_ACCOUNT_DETAILS: 'GOT_ACCOUNT_DETAILS',
  GOT_ACCOUNT_INVOICES: 'GOT_ACCOUNT_INVOICES',
  GOT_ACCOUNT_CHARGES: 'GOT_ACCOUNT_CHARGES',
  GOT_ACCOUNT_STATEMENTS: 'GOT_ACCOUNT_STATEMENTS',
  GOT_ACCOUNT_DEBT: 'GOT_ACCOUNT_DEBT',
  GOT_ACCOUNT_OUTSTANDING: 'GOT_ACCOUNT_OUTSTANDING',
};

const gotAccountDebt = data => ({
  type: actions.GOT_ACCOUNT_DEBT,
  data,
});

const gotAccountOutstanding = data => ({
  type: actions.GOT_ACCOUNT_OUTSTANDING,
  data,
});

const gotAccountDetails = data => ({
  type: actions.GOT_ACCOUNT_DETAILS,
  data,
});

const gotAccountInvoices = data => ({
  type: actions.GOT_ACCOUNT_INVOICES,
  data,
});

const gotAccountCharges = data => ({
  type: actions.GOT_ACCOUNT_CHARGES,
  data,
});

const gotAccountStatements = data => ({
  type: actions.GOT_ACCOUNT_STATEMENTS,
  data,
});

export const downloadInvoice = (invoiceId, billrunKey) => (dispatch) => {
  const accountQuery = getDownloadInvoiceQuery(invoiceId, billrunKey);
  return dispatch(apiBillRunUser(accountQuery))
    .then((success)=> Promise.resolve(success))
    .catch((error) => dispatch(apiBillRunErrorHandler(error)))
}

export const getAccountDebt = () => dispatch => dispatch(apiBillRunUser(getAccountDebtQuery()))
  .then((success)=> dispatch(gotAccountDebt(success.data)))
  .catch((error) => dispatch(apiBillRunErrorHandler(error)))

export const getAccountOutstanding = () => dispatch => dispatch(apiBillRunUser(getAccountOutstandingQuery()))
  .then((success)=> dispatch(gotAccountOutstanding(success.data)))
  .catch((error) => dispatch(apiBillRunErrorHandler(error)));

export const getAccountCharges = () => (dispatch, getState) => {
  const store = getState();
  const filters = getListFilterValuesSelector(store, 'charge');
  const filterQuery = listFilterBuilder(filters, store);
  return dispatch(apiBillRunUser(getAccountChargesQuery(filterQuery)))
    .then((success)=> dispatch(gotAccountCharges(success.data)))
    .catch((error) => dispatch(apiBillRunErrorHandler(error)))
}

export const getAccountStatement = () => (dispatch, getState) => {
  const store = getState();
  const filters = getListFilterValuesSelector(store, 'statement');
  const sorts = getListSortValuesSelector(store, 'statement');
  const filterQuery = listFilterBuilder(filters, store);
  return dispatch(apiBillRunUser(getAccountStatementsQuery(filterQuery, sorts)))
    .then((success)=> dispatch(gotAccountStatements(success.data)))
    .catch((error) =>  dispatch(apiBillRunErrorHandler(error)))
}

export const getAccountInvoices = () => dispatch => dispatch(apiBillRunUser(getAccountInvoicesQuery()))
  .then((success)=> dispatch(gotAccountInvoices(success.data)))
  .catch((error) => dispatch(apiBillRunErrorHandler(error)))

export const getAccountDetails = () => dispatch => dispatch(apiBillRunUser(getAccountQuery()))
  .then((success)=> dispatch(gotAccountDetails(success.data)))
  .catch((error) => dispatch(apiBillRunErrorHandler(error)))

export const updateAccountPassword = (oldPassword, newPassword, successMessage) => dispatch => 
  dispatch(apiBillRunUser(updateAccountQuery(oldPassword, newPassword)))
    .then((success)=> {
      dispatch(showSuccess(successMessage));
      return Promise.resolve();
    } )
    .catch((error) => {
      dispatch(apiBillRunErrorHandler(error));
      return Promise.reject();
    })
