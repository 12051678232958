const en = {
  page: {
    title: {
      home: "Dashboard",
      login: "Login",
      myAccount: "My Account",
      invoices: "Invoices",
      usage: "Usage",
      charges: "Charges",
      statement: "Statement",
      signup: "Sign Up",
      reset_password: "Reset Password",
      404: "Page Not Found 404",
    },
    details: {
      404: 'Sorry, we could not find the page you are looking for.',
      signup: ' ',
      forgot_password: ' ',
    }
  },
  usage_type: {
    call: 'Call',
    incoming_call: 'Incoming Call',
    sms: 'SMS',
    data: 'Data',
    charge: 'Charge',
    flat: 'Flat',
    discount: 'Discount',
    credit: 'Credit',
    mms: 'MMS',
  },
  lbl: {
    confirm_discard: 'Are you sure you want to close and discard all changes?',
    change_password: 'Change password',
    version: "App version",
    loading:"Loading...",
    logout: "logout",
    login: "login",
    hello_account: "{icon} {name}",
    plan_usage: "My Plan Usage",
    plan_unlimited_usage: "My Plan Unlimited Usage",
    out_group_usage: "Out Bundle Usage",
    my_plan: "My Plan",
    plan: "Plan",
    account_details: "Account Details",
    account_subscribers: "Account`s {count} Subscribers Details",
    used_of: "{used} of {of}",
    minutes: "minutes",
    days_cycle_left: "Days To Reset",
    next_invoicing_date: "Next Payment Date",
    select_subscriber: "Please select subscriber",
    password: {
      short: 'Too short',
      score1: 'Really Weak',
      score2: 'Weak',
      score3: 'Okay',
      score4: 'Good',
      score5: 'Strong',
      uppercase: 'Uppercase letters',
      lowercase: 'Lowercase letters',
      number: 'Numbers',
      symbols: 'Symbols',
      hint: "Password should be at least {pass_len} characters and contain: {requirements}",
    },
    request_reset_password_success: "One-time link to reset your password was sent by email, please check your mail.",
    reset_password_success: "Password has been successfully changed",
  },
  field: {
    captcha: 'Verification Code',
    selected_subscriber: 'Subscriber',
    login_form: {
      user_name: 'User Name',
      user_password: 'Password',
      forgot_password: 'Forgot ?',
    },
    new_password: {
      user_name: 'User Name',
      old_password: 'Old Password',
      password1: 'New Password',
      password2: 'Confirm Password',
    },
    subscriber: {
      full_name: "Name",
      a_number: "MSISDN",
      sid: "Reference",
      first_name: 'First Name',
      last_name: 'Last Name',
      plan: 'Plan',
      plan_description: 'Plan',
    },
    account: {
      customer_no: 'Customer No.',
      firstname: 'First Name',
      lastname: 'Last Name',
      address: 'Address',
      country: 'Country',
      city: 'City',
      email: 'Email',
      zip_code: 'Zip Code',
      phone: 'Phone',
      invoice_shipping_method: 'Invoice Shipping Method',
    },
  },
  table: {
    filter: 'Filters',
    refresh: 'Refresh',
    row_actions: 'Actions',
    download: 'Download',
    download_csv: 'Download CSV',
    empty_header: ' ',
    statement: {
      total_outstanding: 'Outstanding Balance',
      date: 'Date',
      description	: 'Description',
      total: 'Total (CHF)',
      credit: 'Credit',
      debit: 'Debit',
      type_invoice: 'Invoice',
      type_payment: 'Payment',
      description_customer_balance: "Customer balance",
    },
    usage: {
      urt: 'Date',
      from: 'From',
      to: 'To',
      country: 'Country',
      type: 'Call Type',
      duration: 'Duration/Events',
      aid: 'Customer ID',
      sid: 'Subscriber ID',
      plan: 'Plan',
      stamp: 'ID',
      billrun: 'Bill',
      final_charge: 'Final Charge',
      to_data: 'Mobile Data',
      only_chargeable: 'Only Chargeable',
      not_chargeable: 'Not Chargeable',
    },
    charge: {
      date: 'Start Date',
      due_date: 'End Date',
      description	: 'Description',
      quantity	: 'Quantity',
      total: 'Total (CHF)',
      billrun: 'Invoice',
    },
    invoice: {
      invoice: 'Invoice',
      customer: 'Customer',
      invoice_number: 'Invoice Number',
      due_date: 'Due Date',
      method: 'Method',
      date: 'Date',
      charge: 'Charge',
      paid: 'Paid',
    },
  },
  placeholder: {
    select: 'Select...',
    captcha: 'Please enter verification code...',
    login_form: {
      user_name: 'Please enter your user customer number...',
      user_password: 'Please enter your password...',
    },
    new_password: {
      old_password: 'Please enter old password...',
      password: 'Please enter new password...',
      reenter_password: 'Please re-enter new password...',
    },
    subscriber_switcher: {
      select_subscriber: 'Select subscriber...',
    }
  },
  btn: {
    yes: "Yes",
    no: "No",
    ok: "OK",
    save: "Save",
    cancel: "Cancel",
    filter: 'Filter',
    signup: 'Sign Up',
    login: 'Log In',
    reset: 'Reset',
    send: 'Send',
    change_password: 'Change password'
  },
  error: {
    pass_not_match: "Password and confirm password does not match",
    missing_required_field: "Missing required field: {field}",
    general: "Oops, something went wrong.. please try again later",
    app_login: "App can not connect, please try later",
    user_login_error: "Incorrect user name or password",
    missing_required_fields: "Missing required fields(s): {fields}",
    signup_fail: "Something is wrong please try again later or contact our customer service.",
    request_reset_password_fail: "Something is wrong please try again later or contact our customer service.",
    reset_password_fail: "Something is wrong please try again later or contact our customer service.",
    reset_password_strength: "Invalid new password",
    captcha_invalid: "Invalid verification code"
  },
  usage_groups: {
    "7ACHT_YOUNG_DATA_BUNDLE_HOMENETWORK_GROUP": "Unlimited Data in Homenetwork",
    "7ACHT_YOUNG_DATA_BUNDLE_EU_GROUP": "Data in EU",
    "7ACHT_YOUNG_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimited calls to FL Mobile",
    "7ACHT_YOUNG_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Calls in Homenetwork & EU",
    "7ACHT_YOUNG_CALL_BUNDLE_EU_TO_CH_GROUP": "Calls in EU to CH",
    "7ACHT_YOUNG_INCOMING_CALLS_GROUP": "Unlimited incoming calls in EU",
    "7ACHT_YOUNG_OUTGOING_SMS_GROUP": "Unlimited SMS in Homenetwork & EU",
    "7ACHT_19_DATA_BUNDLE_GROUP": "Data in Homenetwork & EU",
    "7ACHT_19_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimited calls to FL Mobile",
    "7ACHT_19_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Calls in Homenetwork & EU",
    "7ACHT_19_CALL_BUNDLE_EU_TO_CH_GROUP": "Calls in EU to CH",
    "7ACHT_19_INCOMING_CALLS_GROUP": "Unlimited incoming calls in EU",
    "7ACHT_19_OUTGOING_SMS_GROUP": "Unlimited SMS in Homenetwork & EU",
    "7ACHT_29_DATA_BUNDLE_GROUP": "Data in EU",
    "7ACHT_29_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimited calls to FL Mobile",
    "7ACHT_29_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Calls in Homenetwork & EU",
    "7ACHT_29_CALL_BUNDLE_EU_TO_CH_GROUP": "Calls in EU to CH",
    "7ACHT_29_INCOMING_CALLS_GROUP": "Unlimited incoming calls in EU",
    "7ACHT_29_OUTGOING_SMS_GROUP": "Unlimited SMS in Homenetwork & EU",
    "7ACHT_49_DATA_BUNDLE_GROUP": "Data in EU",
    "7ACHT_49_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimited calls to FL Mobile",
    "7ACHT_49_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Calls in Homenetwork & EU",
    "7ACHT_49_CALL_BUNDLE_EU_TO_CH_GROUP": "Calls in EU to CH",
    "7ACHT_49_INCOMING_CALLS_GROUP": "Unlimited incoming calls in EU",
    "7ACHT_49_OUTGOING_SMS_GROUP": "Unlimited SMS in Homenetwork & EU",
    "7ACHT_69_DATA_BUNDLE_HOMENETWORK_GROUP": "Unlimited Data in Homenetwork",
    "7ACHT_69_DATA_BUNDLE_EU_GROUP": "Data in EU",
    "7ACHT_69_CALL_BUNDLE_EU_TO_CH_GROUP": "Unlimited calls to FL Mobile",
    "7ACHT_69_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimited calls in Homenetwork & EU",
    "7ACHT_69_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Calls in EU to CH",
    "7ACHT_69_INCOMING_CALLS_GROUP": "Unlimited incoming calls in EU",
    "7ACHT_69_OUTGOING_SMS_GROUP": "Unlimited SMS in Homenetwork & EU",
    "7ACHT_99_DATA_BUNDLE_HOMENETWORK_GROUP": "Unlimited Data in Homenetwork",
    "7ACHT_99_DATA_BUNDLE_EU_GROUP": "Data in EU",
    "7ACHT_99_CALL_BUNDLE_FL_MOBILE_GROUP": "Unlimited calls to FL Mobile",
    "7ACHT_99_CALL_BUNDLE_FL_CH_EU_USA_CANADA_EU_GROUP": "Unlimited SMS in Homenetwork & EU",
    "7ACHT_99_CALL_BUNDLE_EU_TO_CH_GROUP": "Calls in EU to CH",
    "7ACHT_99_INCOMING_CALLS_GROUP": "Unlimited incoming calls in EU",
    "7ACHT_99_OUTGOING_SMS_GROUP": "Unlimited SMS in Homenetwork & EU",
    "7ACHT_DATA_DATA_BUNDLE_HOMENETWORK_GROUP": "Unlimited Data in Homenetwork",
    "7ACHT_49_DATA_BUNDLE_HOMENETWORK_GROUP": "Data in Homenetwork",
    "7ACHT_29_DATA_BUNDLE_HOMENETWORK_GROUP": "Data in Homenetwork",
    "7ACHT_99_DATA_BUNDLE_USA_CAN": "Data in USA & Canada",
  },
};

export default en;