import React from 'react';

const Home_de = () => (    
  <span>
    <p><strong>Lieber 7acht Kunde,<br /> 	herzlich willkommen auf der 7acht online Plattform.</strong></p>
    <p>Auf dieser Startseite wird der aktuelle Verbrauch der Inklusiveinheiten angezeigt.</p>
    <p>Unter dem Reiter "Rechnungen" können die Rechnungen angezeigt, bzw. als PDF-Datei herunter geladen werden.<br /> 	Eine detaillierte Anrufliste kann unter dem Reiter "Verbrauch" eingesehen werden.</p>
    <p>Die Kundendaten werden im Reiter "Meine Daten" angezeigt.</p>
    <hr />
    <p><strong>Rechnungslayout</strong><br />Gibt Ihnen einen schnellen Überblick was Sie noch zu bezahlen haben und wie Sie Ihr Abo genutzt haben.<br /> 	<a href="https://www.7acht.li/rechnungserklaerung/" target="_blank" rel="noreferrer">=&gt; Rechnungserklärung</a></p>
    <p><strong>Vereinfachte Zahlungsmöglichkeit</strong><br /> 	Auf jeder Rechnung sehen Sie welcher Betrag noch ausstehend ist oder wieviel Guthaben Sie haben. Dies Erlaubt es Ihnen auch Vorauszahlungen zu machen im Falle einer längeren Abwesenheit oder falls Sie Ihre Rechnungen nicht jeden Monat bezahlen wollen.<br /> 	Für die Bezahlung Ihrer Rechnung können Sie ab sofort auch immer die gleiche Referenznummer verwenden.</p>
    <p><strong>Lastschriftverfahren </strong><br /> 	Mit dem Lastschriftverfahren (LSV+) erledigt sich das Begleichen Ihrer Rechnung ganz von selbst. Sie wird jeden Monat zuverlässig direkt Ihrem Konto belastet.<br /> 	Um Ihre Rechnungen via Lastschriftverfahren zu begleichen, füllen Sie bitte das LSV-Formular aus und senden es an Ihre Bank. <a href="https://www.7acht.li/hilfe/" target="_blank" rel="noreferrer">Formulare finden Sie hier.</a><br /></p>
  </span>
);

export default Home_de;


