import React from 'react';
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Card, Col, Row, Container } from 'react-bootstrap';
import { SubscriberSwitcher, HomeSubscriberDetails } from 'components/Elements';
import { 
  getAccountFullNameSelector,
} from 'selectors/accountSelectors';


import StaticContent from 'components/Pages/StaticContent/Home_de';


const Home = () => {
  const accountName = useSelector(getAccountFullNameSelector);
  const accountIcon = (<i className="bi bi-person-fill" />);
  return (
    <Container>
      <Row>
        <Col>
          <Card className="p-3">
          <Row>
            <Col>
              <Card.Title>
                <FormattedMessage id="lbl.hello_account" values={{ name: accountName, icon:accountIcon }}/>
              </Card.Title>
            </Col>
            <Col>
              <SubscriberSwitcher />
            </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Card className="p-3">
            <HomeSubscriberDetails />
          </Card>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Card className="p-3">
          <StaticContent />
          </Card>
        </Col>
      </Row>

    </Container>
  );
}

export default Home;
