export const actions = {
  SET_FILTER: 'SET_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  SET_TOTAL_PAGE: 'SET_TOTAL_PAGE',
  REMOVE_TOTAL_PAGE: 'REMOVE_TOTAL_PAGE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  REMOVE_CURRENT_PAGE: 'REMOVE_CURRENT_PAGE',
  SET_SIZE: 'SET_SIZE',
  REMOVE_SIZE: 'REMOVE_SIZE',
  SET_SORT: 'SET_SORT',
  TOGGLE_SORT: 'TOGGLE_SORT',
  REMOVE_SORT: 'REMOVE_SORT',
};


export const setSort = (entity, field, sort) => ({
  type: actions.SET_SORT,
  entity,
  field,
  sort,
});

export const toggleSort = (entity, field) => ({
  type: actions.TOGGLE_SORT,
  entity,
  field,
});

export const removeSort = (entity, field) => ({
  type: actions.REMOVE_SORT,
  entity,
  field,
});

export const setSize = (entity, value) => ({
  type: actions.SET_SIZE,
  entity,
  value,
});

export const removeSize = (entity) => ({
  type: actions.REMOVE_SIZE,
  entity,
});

export const setTotalPages = (entity, data) => {
  const value  = data?.total_pages;
  return ({
    type: actions.SET_TOTAL_PAGE,
    entity,
    value,
  });
}

export const removeTotalPages = (entity) => ({
  type: actions.REMOVE_TOTAL_PAGE,
  entity,
});

export const setCurrentPages = (entity, value) => ({
  type: actions.SET_CURRENT_PAGE,
  entity,
  value,
});

export const removeCurrentPages = (entity) => ({
  type: actions.REMOVE_CURRENT_PAGE,
  entity,
});

export const setFilter = (entity, field, value) => ({
  type: actions.SET_FILTER,
  entity,
  field,
  value,
});

export const removeFilter = (entity, field) => ({
  type: actions.REMOVE_FILTER,
  entity,
  field,
});
