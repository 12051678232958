import Immutable from 'immutable';
import { createSelector } from 'reselect';

const getModal = state => state.application.modal;

export const getConfirmModalSelector = createSelector(
  getModal,
  modal => modal.get('confirm'),
);

export const confirmSelector = createSelector(
  getConfirmModalSelector,
  confirm => confirm,
);


export const getFormModalSelector = createSelector(
  getModal,
  modal => modal.get('form'),
);

export const formModalShowStateSelector = createSelector(
  getFormModalSelector,
  modal => modal.get('show', false),
);

export const formModalItemSelector = createSelector(
  getFormModalSelector,
  modal => modal.get('item', Immutable.Map()),
);

export const formModalComponentSelector = createSelector(
  getFormModalSelector,
  modal => modal.get('component', null),
);

export const formModalConfigSelector = createSelector(
  getFormModalSelector,
  modal => modal.get('config', Immutable.Map()),
);

export const formModalErrorsSelector = createSelector(
  getFormModalSelector,
  modal => modal.get('errors', Immutable.Map()),
);
