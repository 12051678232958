import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import classNames from 'classnames';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import { useQuery } from 'common/Hooks';
import Filed from 'components/Fields';
import { PasswordConfirm } from 'components/Elements';
import { userSignUp } from 'actions/authActions';
import { showDanger } from 'actions/alertsActions';
import { getConfig, passwordHint } from 'common/Util';


const Signup = () => {
  const { token = '', username = '', submitted = '' } = useQuery();
  let history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [passwordStrengthError, setPasswordStrengthError] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [captchaCode, setCaptchaCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isInvalidCode, setIsInvalidCode] = useState(false);

  const minLength = getConfig('passwordMinLength', 6);
  const passwordMatched = password1 === password2 && password1 !== '';
  const allowSubmit = password1.length >= minLength && passwordMatched && verificationCode !== '';

  const passHintState = classNames('text-center mb-3', {
    'color-1': !passwordStrengthError,
    'text-danger': passwordStrengthError,
  });

  const onChangeCaptchaCode = (code) => {
    setVerificationCode(code);
    setIsInvalidCode(false);
  }

  const onResetCaptchaCode = (code) => {
    setCaptchaCode(code);
    setIsInvalidCode(false);
  }

  const isValidResetRequest = () => {
    if (password1 !== password2) {
      return false;
    }
    if (captchaCode !== verificationCode) {
      setIsInvalidCode(true);
      return false;
    }
    return true;
  }

  const onChangePassword1 = (password) => {
    setPasswordStrengthError(false);
    setPassword1(password);
  }
  
  const onChangePassword2 = (password) => {
    setPasswordStrengthError(false);
    setPassword2(password);
  }
  
  const onSubmit = (e) => {
    e.preventDefault();
    if (isValidResetRequest()) {
      dispatch(userSignUp(username, password1, token))
        .then((s) => { history.push("/login");})
        .catch((e) => {
          if (e === 'pass_strength') {
            dispatch(showDanger(intl.formatMessage({ id: 'error.reset_password_strength' })));
            setPasswordStrengthError(true);
          } else {
            history.push({ pathname: '/signup', search: "?submitted=error" });
          }
        });
    }
  }

  if (submitted === 'error' || token === '' || username === '') {
    return (
      <Container>
        <Row>
          <Col>
            <p><FormattedMessage id="error.signup_fail" /></p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (    
    <Container>
      <Row>
        <Col>
          <p><FormattedMessage id="page.details.signup" /></p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4" className="text-end">
                <FormattedMessage id="field.new_password.user_name" />
              </Form.Label>
              <Col sm="6">
                <Filed
                  value={username}
                  editable={false}
                />
              </Col>
            </Form.Group>
            <PasswordConfirm
              password1={password1}
              password2={password2}
              onPasswordChange={onChangePassword1}
              onConfirmPasswordChange={onChangePassword2}
            />
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4" className="text-end">
                <FormattedMessage id="field.captcha" />
              </Form.Label>
              <Col sm="6">
                <Filed
                  fieldType="captcha"
                  value={verificationCode}
                  onChange={onChangeCaptchaCode}
                  onChangeCode={onResetCaptchaCode}
                  hasError={isInvalidCode}
                />
              </Col>
            </Form.Group>
            <Col  sm="12"className={passHintState}>
              <Col sm={{ span: 8, offset: 2 }}>
                <small>* {passwordHint(intl)}</small>
              </Col>
            </Col>
            <Col sm="12" className="text-center">
              <Button type="submit" variant="success" onClick={onSubmit} className="text-end" disabled={!allowSubmit}>
                <FormattedMessage id="btn.signup" />
              </Button>
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Signup;
