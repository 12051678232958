import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useIntl } from 'react-intl'


const ConfirmModal = props => {
  const intl = useIntl();
  const labelOkTranslated = props.labelOk ? props.labelOk: intl.formatMessage({id:'btn.ok'});
  const labelCancelTranslated = props.labelCancel ? props.labelCancel: intl.formatMessage({id:'btn.cancel'});
  return(
    <Modal show={props.show}>
      <Modal.Header closeButton={false}>
        <Modal.Title>{ props.message }</Modal.Title>
      </Modal.Header>
      { props.children &&
        <Modal.Body>
          { props.children }
        </Modal.Body>
      }
      <Modal.Footer>
        {props.type !== 'alert' && (
          <Button size="small" className="btn-min-width ms-5" onClick={props.onCancel} variant="primary">
            {labelCancelTranslated}
          </Button>
        )}
        <Button size="small" className="btn-min-width" onClick={props.onOk} variant={props.type === 'delete' ? 'danger' : 'primary'}>
          {labelOkTranslated}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
ConfirmModal.defaultProps = {
  show: false,
  type: 'confirm',
  children: null,
  labelCancel: null,
  labelOk: null,
  onCancel: null,
};

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['confirm', 'delete', 'alert']),
  children: PropTypes.node,
  labelCancel: PropTypes.string,
  labelOk: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func.isRequired,
};

export default ConfirmModal;
