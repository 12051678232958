import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useIntl } from 'react-intl'


const ModalWrapper = props => {
  const intl = useIntl();
  const labelOkTranslated = props.labelOk ? props.labelOk: intl.formatMessage({id:'btn.save'});
  const labelCancelTranslated = props.labelCancel ? props.labelCancel: intl.formatMessage({id:'btn.cancel'});
  return (
    <Modal show={props.show} size={props.modalSize}>
      <Modal.Header closeButton={props.onHide !== null} onHide={props.onHide}>
        <Modal.Title>{ props.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { props.children }
      </Modal.Body>
      { (props.onCancel || (props.onOk && props.showOnOk)) &&
        <Modal.Footer>
          { props.onCancel && (
            <Button size="small" className="btn-min-width" onClick={props.onCancel} variant={props.styleCancel}>
              { labelCancelTranslated }
            </Button>
          ) }
          { props.onOk && props.showOnOk && (
            <Button size="small" className="btn-min-width" onClick={props.onOk} variant={props.styleOk} disabled={props.progress}>
              { props.progress && (<span><i className="fa fa-spinner fa-pulse" />&nbsp;&nbsp;</span>) }
              { (props.progress && props.labelProgress !== null)
                ? props.labelProgress
                : labelOkTranslated
              }
            </Button>
          ) }
        </Modal.Footer>
      }
    </Modal>
  );
}

ModalWrapper.defaultProps = {
  children: null,
  title: '',
  show: false,
  labelOk: undefined,
  labelCancel: undefined,
  labelProgress: null,
  progress: false,
  modalSize: undefined,
  showOnOk: true,
  styleOk: 'primary',
  styleCancel: 'primary',
  onCancel: null,
  onHide: null,
  onOk: null,
};

ModalWrapper.propTypes = {
  children: PropTypes.element,
  title: PropTypes.node,
  show: PropTypes.bool.isRequired,
  labelOk: PropTypes.string,
  labelProgress: PropTypes.string,
  labelCancel: PropTypes.string,
  showOnOk: PropTypes.bool,
  progress: PropTypes.bool,
  modalSize: PropTypes.oneOf(['lg', 'sm', undefined]),
  styleOk: PropTypes.string,
  styleCancel: PropTypes.string,
  onOk: PropTypes.func,
  onHide: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ModalWrapper;
