import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, NavDropdown } from 'react-bootstrap';
import { sentenceCase } from 'change-case';
import classNames from 'classnames';
import { setMainMenuState } from 'actions/applicationActions';
import { userDoLogout } from 'actions/authActions'
import { mainMenuStateSelector } from 'selectors/applicationSelector';
import { isUserAuthorizedSelector } from 'selectors/authSelector';
import { getAccountFullNameSelector } from 'selectors/accountSelectors';
import { getConfig } from 'common/Util';


const Header = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const accountFullName = useSelector(getAccountFullNameSelector);
  const mainMenuState = useSelector(mainMenuStateSelector);
  const isUserAuthorized = useSelector(isUserAuthorizedSelector);

  const logoPath = getConfig(['env', 'appLogo'], getConfig(['env', 'defaultLogo'], 'None'));
  const image = require('../../' + logoPath).default;
  const menuBtnClass = classNames('bi bi-lg' , {
    'bi-filter-left': mainMenuState,
    'bi-list': !mainMenuState,
  });

  const switchMainMenuState = () => {
    dispatch(setMainMenuState(!mainMenuState));
  }

  const onUserNav = (menuItem) => {
    switch (menuItem) {
      case 'logout': {
        return dispatch(userDoLogout());
      }
      default:
        return;
    }
  }

  const login = () => {
    history.push("/login");
  }

  const menuLabel = (<><i className="bi bi-person-circle"></i> {accountFullName}</>);
  
  return (    
    <header id="app-header">
      <span className="float-start">
          <Button
            variant="link"
            className="px-3 py-1 main-menu-toggle shadow-none d-inline-block d-md-none"
            onClick={switchMainMenuState}
          >
            <i className={menuBtnClass} /> 
          </Button>
        <img src={image} className="app-logo ps-2 pe-2 bg-color-green-light" alt="logo"/>
      </span>
      <span className="float-end">
        {isUserAuthorized && (
          <NavDropdown title={menuLabel} id="user-nav-dropdown" onSelect={onUserNav}>
            <NavDropdown.Item href="#" eventKey='logout'>
              <FormattedMessage id="lbl.logout" >
                {message => sentenceCase(message[0])}
              </FormattedMessage>
            </NavDropdown.Item>
          </NavDropdown>
        )}
        {!isUserAuthorized && (
          <Button variant="link" onClick={login}><FormattedMessage id="lbl.login" /></Button>
        )}
      </span>
    </header>
  );
}


export default Header;