const languageConfig = {
  en: {
    isActive: true, // default true
    isDefault: false, // default false
    icon: 'us',
    dir: 'ltr', // default
    code : 'en-GB',
  }, 
  de: {
    isDefault: true, // default false
    isActive: true,
    icon: 'de',
    code : 'de-DE',
  },
  he: {
    isActive: false,
    icon: 'il',
    dir: 'rtl',
    code : 'he-IL',
  },
  fr: {
    isActive: false,
    icon: 'fr',
    code : 'fr-FR',
  }
};

export default languageConfig;
