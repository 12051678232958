import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';


const Text = ({
  onChange, value, editable, disabled, suffix, prefix, ...otherProps
}) => {
  const onChangeValue = (e) => {
    const { value } = e.target;
    onChange(value, e);
  }
  if (editable) {
    const input = (
      <input
        {...otherProps}
        type="text"
        className="form-control"
        value={value}
        onChange={onChangeValue}
        disabled={disabled}
      />
    );
    if (suffix !== null || prefix !== null) {
      return (
        <InputGroup>
          {prefix !== null && (<InputGroup.Addon>{prefix}</InputGroup.Addon>)}
          {input}
          {suffix !== null && (<InputGroup.Addon>{suffix}</InputGroup.Addon>)}
        </InputGroup>
      );
    }
    return input;
  }

  return (
    <div className="non-editable-field">
      <span>
        {(prefix !== null) && `${prefix} `}
        {value}
        {(suffix !== null) && ` ${suffix}`}
      </span>
    </div>
  );
};


Text.defaultProps = {
  value: '',
  required: false,
  disabled: false,
  editable: true,
  placeholder: '',
  suffix: null,
  prefix: null,
  onChange: () => {},
};

Text.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  placeholder: PropTypes.string,
  suffix: PropTypes.node,
  prefix: PropTypes.node,
  onChange: PropTypes.func,
};

export default Text;
