import { createSelector } from 'reselect';

const getProgressIndicator = (state, props) => // eslint-disable-line no-unused-vars
   state.application.progressIndicator.getIn(['progresses'], 0);

const getProgressFullScreen = (state, props) => // eslint-disable-line no-unused-vars
   state.application.progressIndicator.getIn(['isFullScreen'], 0);

export const progressIndicatorSelector = createSelector(
  getProgressIndicator,
  progressIndicator => progressIndicator,
);

export const progressIndicatorIsFullSelector = createSelector(
  getProgressFullScreen,
  isFullScreen => isFullScreen,
);