import React from 'react';
// import { FormattedMessage } from 'react-intl';
import HeaderField from './HeaderField';


const Header = ({ fields, entity, hasActions = false }) => (
  <tr>
    {fields.map((field, idx) => (
      <HeaderField key={field.get('id', idx)} entity={entity} field={field} />
    ))}
    { hasActions && (
      <th className="actions">
        {/*<FormattedMessage id={'table.row_actions'} />*/}
      </th>
    )}
  </tr>
  );

export default Header;