import React from 'react';
import { FormattedMessage} from 'react-intl';
import { getConfig } from 'common/Util';

const appVersion = getConfig(['env', 'APP_VERSION'],'');

const Footer = () => (    
  <footer id="app-footer">
     <span className="float-start ms-3">
      Salt (Liechtenstein) AG - <span className="color-2"><a href="http://www.7acht.li">www.7acht.li</a> - <a href="http://www.7acht.li/rechtliche-hinweise" title="Legal Notice">Imprint and legal information</a></span>
     </span>
      <span className="float-end me-3">
      <FormattedMessage id="lbl.version" /> <strong>{appVersion}</strong>
    </span>
  </footer>
);

export default Footer;