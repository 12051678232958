import { PasswordConfirm } from 'components/Elements';
import { Col, Row, Form, Alert } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import Filed from 'components/Fields';


const ChangePassword = ({ item, updateField, setError, errors }) => {
  const intl = useIntl();

  const error = errors.get('pass', false);
  const setPasswordOld = value => {
    setError('pass');
    updateField('passwordOld', value);
  }
  const setPassword1 = value => {
    setError('pass');
    updateField('password1', value);
  }
  const setPassword2 = value => {
    setError('pass');
    updateField('password2', value);
  }
  return (
    <div className="change-password">
      {error && (<Alert variant="danger">{error}</Alert>)}

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4" className="text-end">
          <FormattedMessage id="field.new_password.old_password" />
        </Form.Label>
        <Col sm="6">
          <Filed
            fieldType="password"
            value={item.get('passwordOld', '')}
            onChange={setPasswordOld}
            placeholder={intl.formatMessage({id:'placeholder.new_password.old_password'})}
          />
        </Col>
      </Form.Group>

      <PasswordConfirm
        password1={item.get('password1', '')}
        password2={item.get('password2', '')}
        onPasswordChange={setPassword1}
        onConfirmPasswordChange={setPassword2}
      />
    </div>
  );
}

export default ChangePassword;
