import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { LoginForm } from 'components/Elements';
import Content from 'components/Layout/Content';
import { isUserAuthorizedSelector } from 'selectors/authSelector';
import { getAccountDetails } from 'actions/accountActions';


const UserAuthWrapper = ({ children }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const isUserAuthorized = useSelector(isUserAuthorizedSelector)
  useEffect(() => {
    if (!isUserAuthorized) {
      history.push("/login");
    }
  }, [isUserAuthorized]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (isUserAuthorized) {
      dispatch(getAccountDetails());
    }
  }, [isUserAuthorized]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isUserAuthorized) {
    return (
      <Content>
        <LoginForm />
      </Content>
    );
  }
  return (
    <>
      {children}
    </>
  );
}


UserAuthWrapper.defaultProps = {
};


UserAuthWrapper.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
  ]).isRequired,
}


export default UserAuthWrapper;
