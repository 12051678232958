import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import classNames from 'classnames';
import Action from './Action';

const Actions = ({
  actions,
  data,
}) => {
  const actionsMutable = actions.toJS();
  return (
    <div className="actions-buttons">
      { actionsMutable
      .filter((action) => {
        if (typeof action.show === 'undefined') {
          return true;
        }
        if (typeof action.show === 'function') {
          return action.show(data);
        }
        return action.show;
      })
      .map((action, idx, list) => {
        const isLast = idx === (list.length - 1);
        const actionClass = classNames({
          'me-1': !isLast,
          'me-0': isLast,
        });
        return (
          <span key={`${action.type}_${idx}`} className={actionClass} >
            <Action {...action} data={data} />
          </span>
        );
      })}
    </div>
  );
}

Actions.defaultProps = {
  actions: Immutable.List(),
  data: null,
};

Actions.propTypes = {
  actions:PropTypes.instanceOf(Immutable.List),
  data: PropTypes.any,
};

export default memo(Actions);
