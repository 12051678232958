import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { 
  getAccountSubscribersOptionsSelector,
} from 'selectors/accountSelectors';
import Filed from 'components/Fields';
import {
  getSubscriberDetails,
  clearSubscriberSelected,
  setSubscriberSelected,
} from 'actions/subscriberActions';
import {
  getSubscriberSidSelector,
} from 'selectors/subscriberSelector'


const SubscriberSwitcher = () => {
  const accountSubscribersOptions = useSelector(getAccountSubscribersOptionsSelector);
  const subscriberSid = useSelector(getSubscriberSidSelector);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    // by default set first subscriber
    if (subscriberSid === '' && accountSubscribersOptions.length > 0) {
      const sid = accountSubscribersOptions[0].value;  
      dispatch(setSubscriberSelected(sid));
      dispatch(getSubscriberDetails(sid));
    }
  }, [accountSubscribersOptions.length > 0]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectSubscriber = useCallback((value) => {
    if (value === null) {
      dispatch(clearSubscriberSelected());
    } else {
      dispatch(setSubscriberSelected(value));
      dispatch(getSubscriberDetails(value));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectedOption = useMemo(() => {
    const selectedOption = accountSubscribersOptions.filter(option => option.value === subscriberSid);
    return (selectedOption.length > 0) ? selectedOption[0] : null
  }, [accountSubscribersOptions, subscriberSid]);

  return ( 
    <Filed
      fieldType="select"
      value={selectedOption}
      onChange={onSelectSubscriber}
      options={accountSubscribersOptions}
      clearable={false}
      placeholder={intl.formatMessage({id:'placeholder.subscriber_switcher.select_subscriber'})}
    />
  );
}

export default SubscriberSwitcher;
