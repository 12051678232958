import React, { useEffect, useCallback } from 'react';
import Immutable from 'immutable';
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Container, Card, Badge } from 'react-bootstrap';
import { FormattedMessage} from 'react-intl';
import isNumber from 'is-number';
import classNames from 'classnames';
import List from 'components/List';
import DisplayValue from 'components/DisplayValue';
import { showWarning, showDanger } from 'actions/alertsActions';
import { 
  getAccountStatementSelector,
  isAccountStatementLoadedSelector,
  getAccountOutstandingSelector,
} from 'selectors/accountSelectors';
import {
  getAccountStatement,
  downloadInvoice,
  getAccountOutstanding,
} from 'actions/accountActions';


const Statement = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const outstanding = useSelector(getAccountOutstandingSelector);
  const data = useSelector(getAccountStatementSelector);
  const isDataLoaded = useSelector(isAccountStatementLoadedSelector);

  const getListData = useCallback(() => {
    dispatch(getAccountStatement())
    .catch((e) => {
      dispatch(showWarning(intl.formatMessage({
        id:`error.${e.message}`,
      }, {fields: e.fields}
      )));
    });
  },[dispatch, intl]);


  useEffect(() => {
    if (!isDataLoaded) {
      getListData();
      dispatch(getAccountOutstanding());
    }
  }, [isDataLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isDataLoaded) {
    return null;
  }

  const onClickDownload = (data) => {
    const invoiceId = data.get('invoice_id', '');
    const billrunKey = data.get('billrun_key', '');
    dispatch(downloadInvoice(invoiceId, billrunKey))
    .then(res => {
      if (res.data) {
        dispatch(showDanger(res.data));
      }
    });
  }

  const parseDescription = (value, config) => {
    const isCustomerBalance = config.getIn(['additionData',  'uf.description_key'], '');
    if (isCustomerBalance === 'CUSTOMER_BALANCE_20220201') {
      const date = new Date("2022-02-01");
      return (
        <>
          <FormattedMessage id="table.statement.description_customer_balance" />
          {" "}
          <DisplayValue type='date' value={date.toISOString()} />
        </>
      );
    }
    const type = config.getIn(['additionData',  'type'], '');
    if (type === 'inv') {
      return (
        <>
          <FormattedMessage id="table.statement.type_invoice" defaultMessage={type} />
          {` ${config.getIn(['additionData', 'invoice_id'], '')}`}
        </>
      );
    }
    if (type === 'rec') {
      const date = config.getIn(['additionData', 'urt'], '')
      return (
        <>
          <FormattedMessage id="table.statement.type_payment" defaultMessage={value} />
          {" "}
          <DisplayValue type='date' value={date} />
        </>
      );
    }
    return '';
  };

  const parseDebit = (value, config) => {
    const due = config.getIn(['additionData', 'due'], '');
    if (isNumber(due) && due > 0) {
      const value = config.getIn(['additionData', 'amount'], '');
      return (
        <DisplayValue type='price' value={value} />
      );
    }
    return '';
  }

  const parseCredit = (value, config) => {
    const due = config.getIn(['additionData', 'due'], '');
    if (isNumber(due) && due <= 0) {
      const value = config.getIn(['additionData', 'amount'], '');
      return (
        <DisplayValue type='price' value={value} />
      );
    }
    return '';
  }

  const showDownloadInvoice = data => data.get('type', '') === 'inv';

  const tableFields = Immutable.fromJS([
    { id: 'urt', title: 'table.statement.date', type: 'date', sort: true, defaultSort: -1 },
    { id: 'description', title: 'table.charge.description', parser: parseDescription, additionData: {fields: ['type', 'urt', 'invoice_id', 'uf.description_key']}},
    { id: 'credit', title: 'table.statement.credit', parser: parseCredit, additionData: {fields: ['due', 'amount']}},
    { id: 'debit', title: 'table.statement.debit', parser: parseDebit, additionData: {fields: ['due', 'amount']}},
  ]);

  const tableFilters = Immutable.fromJS([]);

  const tableActions = Immutable.fromJS([
    {type: 'refresh', onClick: getListData, enable: isDataLoaded }
  ]);

  const rowActions = Immutable.fromJS([
    { type: 'download', showIcon: true, onClick: onClickDownload, show: showDownloadInvoice },
  ]);

  const styleOutstanding = classNames({
    'success': isNumber(outstanding) && outstanding < 0,
    'danger': isNumber(outstanding) && outstanding > 0,
    'salt': !isNumber(outstanding) || outstanding === 0,
  });

  const listHeader = (
    <p>
      <Card.Text className="h5 align-bottom" as="span">
        <FormattedMessage id="table.statement.total_outstanding" />:
      </Card.Text> <Badge pill bg={styleOutstanding}><DisplayValue type="price" value={outstanding} /></Badge>
    </p>
  );

  return (
    <Container>
      <Row>
        <Col>
          <Card className="p-3">
            <List
              entity='statement'
              data={data}
              fields={tableFields}
              filters={tableFilters}
              listActions={tableActions}
              rowActions={rowActions}
              reloadData={getListData}
              header={listHeader}
            />
          </Card>
        </Col>
      </Row>
  </Container>
  );
}

export default Statement;
