import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getConfig } from 'common/Util';


export function useTitle(title) {
  const siteTitlePrefix = getConfig(['env', 'site_title_prefix'],'');
  useEffect(() => {
    const prevTitle = document.title;
    if (title) {
      document.title = `${title} | ${siteTitlePrefix}`;
    }
    return () => {
      document.title = prevTitle;
    }
  })
}

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => {
    const params = new URLSearchParams(search);
    let output = {};
    params.forEach((val, key) => {
      output[key] = val;
    });
    return output;
  }, [search]);
}
