import Immutable from 'immutable';
import { actions } from 'actions/applicationActions';


const defaultState = Immutable.Map({
  manMenu: Immutable.Map({
    isOpen: true,
  })
});

const menuReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actions.SET_MAIN_MENU_STATE: {
      const { isOpen } = action;
      return state.setIn(['manMenu', 'isOpen'], isOpen);
    }
    default:
      return state;
  }
}


export default menuReducer;