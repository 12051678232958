import React from 'react';
import Immutable from 'immutable';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux'
import isNumber from 'is-number';
import { getCurrencySelector } from 'selectors/settingsSelectors';


const Price = ({ value, config = Immutable.Map() }) => {
  const currency = useSelector(getCurrencySelector)
  if (isNumber(value)) {
    return (
      <FormattedNumber value={value} style="currency" currency={currency} />
    );
  }
  return value;
}

export default Price;
