import { createSelector } from 'reselect';
import Immutable from 'immutable';
import moment from 'moment';


const getAuth = (state, props) => state.auth;

const getAppAuth = (state, props) => {
  const auth = getAuth(state, props);
  return auth.get('app', false);
}
const getUserAuth = (state, props) => {
  const auth = getAuth(state, props);
  return auth.get('user', false);
}

export const appAuthorizedDetailsSelector = createSelector(
  getAppAuth,
  appAuth => appAuth,
);

export const userAuthorizedDetailsSelector = createSelector(
  getUserAuth,
  userAuth => userAuth,
);

export const isAppAuthorizedSelector = createSelector(
  appAuthorizedDetailsSelector,
  (authorized = Immutable.Map()) => {
    const hasToken =  authorized.get('access_token', '').length > 0;
    if (!hasToken) {
      return false;
    }
    const expiresTime =  authorized.get('expires_time', false);
    if (!expiresTime) {
      return false;
    }
    return expiresTime > moment().unix();
  },
);

export const getAppAuthorizationTokenSelector = createSelector(
  appAuthorizedDetailsSelector,
  (authorized = Immutable.Map()) => ({
    type: authorized.get('token_type', ''),
    token: authorized.get('access_token', ''),
  }),
);

export const isAppAuthorizationErrorSelector = createSelector(
  appAuthorizedDetailsSelector,
  (authorized = Immutable.Map()) => authorized.get('error', false),
);

export const getAppAuthorizedTokenSelector = createSelector(
  appAuthorizedDetailsSelector,
  (authorized = Immutable.Map()) => ({
    type: authorized.get('token_type', ''),
    token: authorized.get('access_token', ''),
  }),
);

export const getUserAuthorizedTokenSelector = createSelector(
  userAuthorizedDetailsSelector,
  (authorized = Immutable.Map()) => ({
    type: authorized.get('token_type', ''),
    token: authorized.get('access_token', ''),
  }),
);

export const isUserAuthorizedSelector = createSelector(
  userAuthorizedDetailsSelector,
  (authorized = Immutable.Map()) => {
    const hasToken =  authorized.get('access_token', '').length > 0;
    if (!hasToken) {
      return false;
    }
    const expiresTime =  authorized.get('expires_time', false);
    if (!expiresTime) {
      return false;
    }
    return expiresTime > moment().unix();
  },
);