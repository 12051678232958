import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from 'configureStore'
import 'styles/index.scss';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

const DevTools = lazy(() => import('./components/DevTools'));

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
      {process.env.NODE_ENV !== "production" && (
        <Suspense fallback={<div>Loading...</div>}>
          <DevTools />
        </Suspense>
      )}
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
