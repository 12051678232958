import Immutable from 'immutable';
import { v4 as uuidv4 } from 'uuid';

export const actions = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
  DISMISS_ALL_ALERTS: 'DISMISS_ALL_ALERTS',
  DISMISS_ALERT: 'DISMISS_ALERT',
  SHOW_ALERT: 'SHOW_ALERT',
};

const timeouts = {
  default: 4000,
  success: 2000,
  warning: 4000,
  danger: 6000,
  info: 4000,
};

const Alert = Immutable.Record({
  id: '',
  type: 'info',
  message: '',
  timeout: timeouts.default,
});

export const showAlert = (message = '', type = actions.INFO, timeout = timeouts.default) => {
  const id = uuidv4();
  const alert = new Alert({
    id,
    type,
    message,
    timeout,
  });
  return {
    type: actions.SHOW_ALERT,
    alert,
  };
}

export const hideAlert = id => ({
  type: actions.DISMISS_ALERT,
  id,
});

export const hideAllAlerts = () => ({
  type: actions.DISMISS_ALL_ALERTS,
});

export const showSuccess = (message = 'Success', timeout = timeouts.success) => 
 showAlert(message, actions.SUCCESS, timeout);


export const showWarning = (message = 'Warning', timeout = timeouts.warning) =>
  showAlert(message, actions.WARNING, timeout);

export const showDanger = (message = 'Error', timeout = timeouts.danger) =>
  showAlert(message, actions.DANGER, timeout);

export const showInfo = (message = 'Info', timeout = timeouts.info) =>
  showAlert(message, actions.INFO, timeout);
