const subscriberConfig = {
  display_name: '{{a_number}} | {{firstname}} {{lastname}} [{{sid}}]',
  msisdn_field: 'a_number',
  display_config: {
    full_name: {
      map: {
        fields: ['firstname', 'lastname'],
      },
    }, 
    a_number: {
      map: {
        template: '00{{a_number}}',
        trimZero: true,
      }
  }},
  display_in_card: [{
    field_name: 'full_name',
    show_label: false,
    icon: 'bi-person-badge',
  }, {
    field_name: 'a_number',
  }, {
    field_name: 'plan_description',
  }],
};

export default subscriberConfig;
