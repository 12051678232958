import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { NavDropdown } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import { setLanguage } from 'actions/applicationActions';
import { activeLanguageSelector } from 'selectors/applicationSelector';
import { getLanguageSettingsSelector } from 'selectors/settingsSelectors';
import { getEnabledLanguages } from 'common/Util';

const LanguageSwitcher = (props) => {
  const dispatch = useDispatch();
  const lang = useSelector(activeLanguageSelector);
  const languageConfig = useSelector(getLanguageSettingsSelector);

  const enabledLanguages = getEnabledLanguages(languageConfig);
  const languageNames = new Intl.DisplayNames([lang], {type: 'language'});

  const selectedLangIcon = languageConfig.getIn([lang, 'icon'], null);
  const selectLabel =  (selectedLangIcon)
    ? <ReactCountryFlag countryCode={selectedLangIcon} svg/>
    : languageNames.of(lang);
  
  const switchLanguage = (lang) => {
    dispatch(setLanguage(lang));
  }
  
  return (    
    <NavDropdown
      id="lang-nav-dropdown"
      title={selectLabel}
      onSelect={switchLanguage}
    >
      { enabledLanguages.map((enabledLanguage, name) => (
        <NavDropdown.Item key={name} href="#" eventKey={name}>
          <ReactCountryFlag countryCode={enabledLanguage.get('icon', '')} svg/> {languageNames.of(name)}
        </NavDropdown.Item>
      ))
      .toList()
      }
    </NavDropdown>
  );
}


export default LanguageSwitcher;