import React from 'react';
import Immutable from 'immutable';
import DisplayValue from 'components/DisplayValue';


const ColumnValue = ({ data, config }) => {
  if (config.has('fixedValue')) {
    return config.get('fixedValue','');
  }
  const value = data.get(config.get('id',''),'');
  // Add addition fields data if required
  let additionData = Immutable.Map();
  if (config.has('additionData')) {
    additionData = config
      .get('additionData', Immutable.Map())
      .reduce((acc, value, type) => {
        if (type === 'fields') {
          return acc.withMutations(accWithMutations => {
            value.forEach(field => {
              accWithMutations.set(field, data.getIn(field.split('.'), ''));
            });
          });
        }
        return acc.set(type, value)
      }, Immutable.Map())
  }
  let configForParser = config.merge({'additionData': additionData});
  if (config.has('parser')) {
    return config.get('parser')(value, configForParser, data);
  }
  switch (config.get('type')) {
    case 'datetime': 
    case 'price':
    case 'date': 
    case 'usage':
    case 'ofOn':
    case 'usageType': {
      return <DisplayValue type={config.get('type')} value={value} config={configForParser} />
    }
    default:
      return value;
  }
}

export default ColumnValue;