import Immutable from 'immutable';
import { actions } from 'actions/applicationActions';


const defaultState = Immutable.Map();

const languageReducer = (state = defaultState, action) => {
  switch (action.type) {

    case actions.SET_LANGUAGE: {
      const { lang } = action;
      return state.set('lang', lang);
    }

    default:
      return state;
  }
}


export default languageReducer;