import { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl';
import Immutable from 'immutable';
import Filed from 'components/Fields';
import { setFilter, removeFilter } from 'actions/listActions';
import { getFilterValueSelector } from 'selectors/listSelectors';

const FilterField = ({ filter, entity }) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => getFilterValueSelector(state, entity, filter.get('id', '')));

  useEffect(() => {
    if (filter.has('default')) {
      dispatch(setFilter(entity, filter.get('id', ''), filter.get('default', '')));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeSelect = (val) => {
    if (val === '' || val === null || (Array.isArray(val) && val.length === 0)) {
      dispatch(removeFilter(entity, filter.get('id', '')));
    } else {
      dispatch(setFilter(entity, filter.get('id', ''), val));
    }
  }
  
  if (filter.has('options')) {
    let options = filter.get('options', Immutable.List());
    if (Immutable.List.isList(options)) {
      options = options.map((label, idx) => ({ value: idx, label })).toArray();
    } else if (Immutable.Map.isMap(options)) {
      options = options.map((label, value) => ({ value, label })).toList().toArray()
    } else if (typeof options === 'function') {
      options = options();
    }

    let selectedOption = null;
    if (filter.get('multi', false)) {
      selectedOption = [...options].filter(option => value.includes(option.value));
      selectedOption = selectedOption.length > 0 ? selectedOption : null;
    } else {
      const selectedOptionIndex = options.findIndex(option => option.value === value);
      selectedOption = selectedOptionIndex !== -1 ? options[selectedOptionIndex]: null;
    }
    
    return (
      <>
        <FormattedMessage id={`table.${entity}.${filter.get('id', '')}`} />
        <Filed
          fieldType="select"
          value={selectedOption}
          onChange={onChangeSelect}
          options={options}
          multi={filter.get('multi', false)}
        />
      </>
    );

  }
}

export default FilterField;
