import { createSelector } from 'reselect';

const getActiveLanguage = (state, props) => // eslint-disable-line no-unused-vars
  state.application.language.get('lang', null);

export const activeLanguageSelector = createSelector(
  getActiveLanguage,
  lang => lang,
);

const getMenuState = (state, props) => // eslint-disable-line no-unused-vars
  state.application.menu.getIn(['manMenu', 'isOpen'], true);

export const mainMenuStateSelector = createSelector(
  getMenuState,
  isOpen => isOpen,
);