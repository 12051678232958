import React from 'react';
import { useSelector } from 'react-redux'
import Immutable from 'immutable';
import { Container, Button } from 'react-bootstrap';
import download from 'downloadjs';
import Papa from 'papaparse';
import flat from 'flat';
import { getLanguageSettingsSelector } from 'selectors/settingsSelectors';
import { getEnabledLanguages } from 'common/Util';


const LanguageFile = () => {
  const languageConfig = useSelector(getLanguageSettingsSelector);
  const enabledLanguages = getEnabledLanguages(languageConfig);
  const languages = enabledLanguages
    .reduce((acc, lang, name) => acc.set(name, require(`lang/${name}`).default), Immutable.Map())
  const langKeys = languages.keySeq().toArray();
  
  const onClickAction = () => {
    const data = languages.reduce((acc, messages, langKey) => acc.withMutations((accWithMutations) => {
      const flatMessages = Immutable.fromJS(flat(messages));
      flatMessages.forEach((translation, key) => {
        accWithMutations.setIn([key, langKey], translation);
      })
    }), Immutable.Map())
    .reduce((acc, translations, key) => {
      return acc.push(Immutable.Map({key}).merge(translations));
    }, Immutable.List())
    .sortBy(translation => translation.get('key', ''))
    .toJS();

    const csv = Papa.unparse({
      fields: ['key', ...langKeys],
      data
    });
    download(csv, "translations.csv", "text/csv");
  }

  const lagToDownload = langKeys.join(', ');

  return (
    <Container>
      <Button onClick={onClickAction} className="ps-2 pe-2">
        <i className="bi bi-download" /> Download file with languages: {lagToDownload}
      </Button>
    </Container>
  );
}

export default LanguageFile;















