import React from 'react';
import { useSelector } from 'react-redux'
import { Nav } from 'react-bootstrap';
import { mainMenuStateSelector } from 'selectors/applicationSelector';
import { getMainMenuSettingsSelector } from 'selectors/settingsSelectors';
import MainMenuItem from './MainMenuItem';


const MainMenu = (props) => {
  const mainMenu = useSelector(getMainMenuSettingsSelector);
  const mainMenuState = useSelector(mainMenuStateSelector);

  if (mainMenu.isEmpty()) {
    return null;
  }
  const items = mainMenu
    .map((item, idx) => (<MainMenuItem data={item} key={idx} mainMenuState={mainMenuState} />))
    .toList();
  return (
    <Nav defaultActiveKey="/" className="flex-column">
      {items}
    </Nav>
  );
}


export default MainMenu;