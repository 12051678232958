const { createProxyMiddleware } = require('http-proxy-middleware');

module.exports = (app) => {
  const backendUrl = process.env.REACT_APP_API_URL;
  app.use(
    '/api',
    createProxyMiddleware({
      target: backendUrl,
      changeOrigin: true,
      pathRewrite: {
        "^/api": "/"
      }
    })
  );
};