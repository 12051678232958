import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FilterField from './FilterField';


const Filter = ({ filters, entity }) => (
  <Row className="pb-1">
    {filters.map((filter, idx) => (
      <Col key={idx}>
        <FilterField filter={filter} entity={entity} />
      </Col>
    ))}
  </Row>
);

export default Filter;