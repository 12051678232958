import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Form } from 'react-bootstrap';
import ClientCaptcha from "react-client-captcha";
import { useIntl, FormattedMessage } from 'react-intl';
import Filed from '../Field';
import { getConfig } from 'common/Util';


const Captcha = ({
  onChange, value, editable, disabled, onChangeCode, hasError, ...otherProps
}) => {
  const intl = useIntl();
  const length = getConfig('captchaLength', 4);
  const chars = getConfig('captchaChars', "1234567890");
  if (editable) {
    return (
      <>
       <InputGroup className='captcha'>
        <Filed
          className='captcha-input'
          value={value}
          onChange={onChange}
          placeholder={intl.formatMessage({id:'placeholder.captcha'})}
        />
        <InputGroup.Text className='captcha-code'>
          <ClientCaptcha
            fontFamily="Hanalei"
            captchaCode={onChangeCode}
            height={36}
            backgroundColor='#e9ecef'
            retryIconSize={16}
            charsCount={length}
            fontSize={28}
            width={125}
            chars={chars}
          />
        </InputGroup.Text>
      </InputGroup>
      {(hasError) && (
        <Form.Text className="text-danger">
          <FormattedMessage id="error.captcha_invalid" />
        </Form.Text>
      )}
      </>
    );
  }

  return null;
};


Captcha.defaultProps = {
  value: '',
  editable: true,
  hasError: false,
  placeholder: '',
  onChange: () => {},
  onChangeCode: () => {},
};

Captcha.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  editable: PropTypes.bool,
  hasError: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onChangeCode: PropTypes.func,
};

export default Captcha;
