export const actions = {
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_MAIN_MENU_STATE: 'SET_MAIN_MENU_STATE',
};

export const setLanguage = (lang) => ({
  type: actions.SET_LANGUAGE,
  lang,
});

export const setMainMenuState = (isOpen) => ({
  type: actions.SET_MAIN_MENU_STATE,
  isOpen,
});