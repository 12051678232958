import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  progressIndicatorSelector,
  progressIndicatorIsFullSelector,
} from 'selectors/progressIndicatorSelector';


const ProgressIndicator = ({ progressIndicator, isFullScreen}) => (
  <div className="progress-indicator-container">
      { progressIndicator && !isFullScreen && (
        <div key="progressIndicator" className="system-progress-indicator" />
      )}
      { progressIndicator && isFullScreen && (
        <div className="system-progress-loading">
          <div className='uil-ring-css'>
            <div/>
          </div>
        </div>
      )}
  </div>
);

ProgressIndicator.defaultProps = {
  progressIndicator: false,
  isFullScreen: false,
};

ProgressIndicator.propTypes = {
  progressIndicator: PropTypes.bool,
  isFullScreen: PropTypes.bool,
};

const mapStateToProps = state => ({
  progressIndicator: progressIndicatorSelector(state) > 0,
  isFullScreen: progressIndicatorIsFullSelector(state) > 0,
});

export default connect(mapStateToProps)(ProgressIndicator);
