const mainMenu = [{
    title: 'page.title.home',
    path: '/',
    icon: 'bi-speedometer',
    auth: true,
  }, {
    title: 'page.title.myAccount',
    path: '/my-account',
    icon: 'bi-person',
    auth: true,
  }, {
    title: 'page.title.invoices',
    path: '/invoices',
    icon: 'bi-receipt-cutoff',
    auth: true,
  }, {
    title: 'page.title.usage',
    path: '/usage',
    icon: 'bi-bar-chart',
    auth: true,
  }, {
  //   title: 'page.title.charges',
  //   path: '/charges',
  //   icon: 'bi-cash-stack',
  //   auth: true,
  // },{
    title: 'page.title.statement',
    path: '/statement',
    icon: 'bi-card-list',
    auth: true,
  },
];

export default mainMenu;
