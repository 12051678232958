import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Button, Col, Row, InputGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Filed from 'components/Fields';
import { userDoLogin } from 'actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { isUserAuthorizedSelector } from 'selectors/authSelector';
import { useIntl } from 'react-intl'


const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const intl = useIntl();
  const dispatch = useDispatch();
  const isUserAuthorized = useSelector(isUserAuthorizedSelector)

  if (isUserAuthorized) {
    return null;
  }

  const submit = (e) => {
    e.preventDefault();
    dispatch(userDoLogin(username, password, intl));
  }

  return (
    <Form>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4" className="text-end">
          <FormattedMessage id="field.login_form.user_name" />
        </Form.Label>
        <Col sm="6">
          <Filed
            value={username}
            onChange={setUsername}
            placeholder={intl.formatMessage({id:'placeholder.login_form.user_name'})}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="4" className="text-end">
          <FormattedMessage id="field.login_form.user_password" />
        </Form.Label>
        <Col sm="6">
          <InputGroup>
            <Filed
              fieldType="password"
              value={password}
              onChange={setPassword}
              placeholder={intl.formatMessage({id:'placeholder.login_form.user_password'})}
            />
            <InputGroup.Text>
              <Link to="/reset-password">
                <FormattedMessage id="field.login_form.forgot_password" />
              </Link>
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>
      <Col sm="12" className="text-center">
        <Button type="submit" variant="success" onClick={submit} className="text-end">
          <FormattedMessage id="btn.login" />
        </Button>
      </Col>
    </Form>
  );
}


export default LoginForm;
