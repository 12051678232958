import React from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Carousel } from 'react-bootstrap';
import AccountSubscribersField from './AccountSubscribersField';
import { getSubscriberSettingsSelector } from 'selectors/settingsSelectors';


const AccountSubscribers = ({ subscribers }) => {
  const subscriberSettings = useSelector(getSubscriberSettingsSelector);
  const cardFields = subscriberSettings.get('display_in_card', Immutable.List());
  return (
    <Carousel variant="dark" interval={null}>
      {subscribers.map((subscriber, idx) => (
        <Carousel.Item className="account-subscribers" key={idx}>
          <Carousel.Caption>
            {cardFields.map((cardField, idx) => (
              <AccountSubscribersField
                key={cardField.get('field_name', idx)}
                config={cardField}
                subscriber={subscriber}
              />
            )).toList()}
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

AccountSubscribers.defaultProps = {
  subscriber: Immutable.List(),
};

AccountSubscribers.propTypes = {
  subscriber: PropTypes.instanceOf(Immutable.List),
};


export default AccountSubscribers;
