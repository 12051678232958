import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import { getEntityFieldValue } from 'common/Util';


const AccountSubscribersField = ({ config, subscriber }) => {
  let output = [];
  if (config.get('icon', '') !== '') {
    output.push(<i className="bi bi-person-badge" key='subscriber-icon' />);
    output.push(" ");
  }
  const fieldName = config.get('field_name', '');
  if (config.get('show_label', true)) {
    const key = `field.subscriber.${fieldName}`;
    output.push(<FormattedMessage id={key} key='subscriber-icon'  />);
    output.push(': ');
  }
  output.push(getEntityFieldValue(config, subscriber));
  return (
    <p>
      {output}
    </p>
  );
}

AccountSubscribersField.defaultProps = {
  subscriber: Immutable.Map(),
  config: Immutable.Map(),
};

AccountSubscribersField.propTypes = {
  subscriber: PropTypes.instanceOf(Immutable.Map),
  config: PropTypes.instanceOf(Immutable.Map),
};


export default AccountSubscribersField;
