import Immutable from 'immutable';
import { actions } from 'actions/listActions';
import { actions as authActions } from 'actions/authActions';
import { getConfig } from 'common/Util';


const defaultState = Immutable.Map();
const defaultListSize = getConfig('defaultListSize', 20);

const listReducer = (state = defaultState, action) => {
  switch (action.type) {

    case actions.SET_SORT: {
      const { entity, field, sort } = action;
      return state.setIn(['sort', entity, field], sort);
    }

    case actions.REMOVE_SORT: {
      const { entity, field } = action;
      return state.deleteIn(['sort', entity, field]);
    }

    case actions.TOGGLE_SORT: {
      const { entity, field } = action;
      const currSort = state.getIn(['sort', entity, field], -1);
      const newSort = currSort === -1 ? 1 : -1;
      return state.setIn(['sort', entity, field], newSort);
    }

    case actions.SET_FILTER: {
      const { entity, field, value} = action;
      return state.setIn(['filter', entity, field], value);
    }

    case actions.REMOVE_FILTER: {
      const { entity, field} = action;
      return state.deleteIn(['filter', entity, field]);
    }

    case actions.SET_CURRENT_PAGE: {
      const { entity, value} = action;
      return state.setIn(['page', entity], value);
    }

    case actions.REMOVE_CURRENT_PAGE: {
      const { entity } = action;
      return state.deleteIn(['page', entity]);
    }

    case actions.SET_SIZE: {
      const { entity, value} = action;
      return state.setIn(['size', entity], value);
    }

    case actions.REMOVE_SIZE: {
      const { entity } = action;
      return state.setIn(['size', entity], defaultListSize);
    }

    case actions.SET_TOTAL_PAGE: {
      const { entity, value} = action;
      return state.setIn(['total', entity], value);
    }

    case actions.REMOVE_TOTAL_PAGE: {
      const { entity } = action;
      return state.deleteIn(['total',entity]);
    }

    case authActions.USER_LOGOUT: {
      return defaultState;
    }
    default:
      return state;
  }
}

export default listReducer;
